import { mode as MODE } from '@api/routes/geolocation'
import { For } from 'solid-js'
import { Button, Div } from '~/components/html'
import { RiMapMapPinLine } from '~/components/icons'
import { VerticalSliderSlide } from '~/components/ui'
import { useRootContext } from '~/contexts'
import { clamp, css, font, layout, shape, size, space } from '~/libs'

type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type PresenterProps = { ctx: ResolvedContext, fly: (center: mapboxgl.LngLatLike) => void }

export function AddressSearchResults() {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const fly = (center: mapboxgl.LngLatLike) => {
    ctx.drawer2()?.moveToIdx(0, true, { duration: 0 })
    ctx.setIsOpenAddress(false)
    ctx.drawer1()?.moveToIdx(0, true, { duration: 0 })
    ctx.setIsOpenSearch(false)
    ctx.drawer4()?.moveToIdx(0, true, { duration: 0 })
    ctx.setMode(MODE.map)
    ctx.map()?.flyTo({ center })
  }
  return <Presenter ctx={ctx} fly={fly} />
}

function Presenter({ ctx, fly }: PresenterProps) {
  const styled = {
    box: css({
      ...layout({ flex: 1 })
    }),
    button: {
      fly: css({
        ...layout({ alignItems: 'center', display: 'flex' }),
        ...shape({ backgroundColor: '#fff' }),
        ...space({ columnGap: '16px' }),
        ...size({ width: '100%' })
      })
    },
    container: css({
      ...shape({ backgroundColor: '#fff', padding: '8px 24px' })
    }),
    icon: css({
      ...layout({ display: 'grid', placeContent: 'center' }),
      ...shape({ backgroundColor: '#ebebeb', borderRadius: '12px' }),
      ...size({ height: '48px', width: '48px' })
    }),
    text: {
      description: css({
        ...clamp(1),
        ...font({ color: '#717171', fontSize: '12px', fontWeight: 400, lineHeight: '16px' })
      }),
      title: css({
        ...clamp(1),
        ...font({ color: '#222', fontSize: '16px', fontWeight: 400, lineHeight: '20px' })
      })
    }
  }
  return (
    <For each={ctx.features()}>
      {({ center, place_name_ja, text_ja }) => (
        <VerticalSliderSlide>
          <Div class={styled.container}>
            <Button class={styled.button.fly} onClick={() => fly(center)}>
              <Div class={styled.icon}>
                <RiMapMapPinLine color='#222' size={22} />
              </Div>
              <Div class={styled.box}>
                <Div class={styled.text.title}>{text_ja}</Div>
                <Div class={styled.text.description}>{place_name_ja.split(',')[1]}</Div>
              </Div>
            </Button>
          </Div>
        </VerticalSliderSlide>
      )}
    </For>
  )
}
