import type { ReturnSearchResult } from '@api/routes/rooms'
import { For, onMount } from 'solid-js'
import { useLocation } from 'solid-start'
import cover from '~/assets/resize/306x290.png'
import { A, Div, Img } from '~/components/html'
import { Requested, VerticalSliderSlide } from '~/components/ui'
import { useVirtualContext } from '~/contexts'
import { clamp, css, font, layout, position, scale, shape, size, space } from '~/libs'

type Props = { items: ReturnSearchResult[] }
type PresenterProps = Props

export function FavoriteListItems(props: Props) {
  const ctx = useVirtualContext()
  if (!ctx) throw new Error('ctx is not defined')
  onMount(() => ctx.slider()?.update())
  return <Presenter {...props} />
}

function Presenter({ items }: PresenterProps) {
  const styled = {
    box: css({
      ...layout({ display: 'grid' }),
      ...space({ rowGap: '1px' })
    }),
    cover: css({
      ...layout({ display: 'flex' }),
      ...position({ position: 'relative' }),
      ...scale({ aspectRatio: '20 / 19' }),
      ...shape({
        backgroundColor: '#f7f7f7',
        border: '3px solid #fff',
        borderRadius: '16px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, .13)'
      }),
      ...space({ marginBottom: '8px' }),
      '& > img': {
        ...scale({ objectFit: 'cover' }),
        ...shape({ borderRadius: '16px' }),
        ...size({ height: '100%', width: '100%' })
      }
    }),
    root: css({
      ...layout({ display: 'grid', gridAutoFlow: 'column', gridTemplateColumns: '1fr 1fr' }),
      ...shape({ padding: '16px 24px 0' }),
      ...space({ columnGap: '24px' })
    }),
    text: {
      address: css({
        ...clamp(2),
        ...font({ color: '#717171', fontSize: '14px', fontWeight: 400, lineHeight: '18px' })
      }),
      name: css({
        ...clamp(2),
        ...font({ color: '#222', fontSize: '16px', fontWeight: 600, lineHeight: '18px' })
      })
    }
  }
  return (
    <VerticalSliderSlide class={styled.root}>
      <For each={items}>
        {({ address, id, name }) => (
          <A href={`/rooms/${id}${useLocation().search}`}>
            <Div class={styled.cover}>
              <Img height={145} src={cover} width={153} />
              <Requested
                id={id}
                position={{ bottom: '3px', left: '50%', position: 'absolute', transform: 'translateX(-50%)' }}
              />
            </Div>
            <Div class={styled.box}>
              <Div class={styled.text.name}>{name}</Div>
              <Div class={styled.text.address}>{address}</Div>
            </Div>
          </A>
        )}
      </For>
    </VerticalSliderSlide>
  )
}
