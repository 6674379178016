import { For } from 'solid-js'
import { Button, H3 } from '~/components/html'
import { HorizontalSlider, HorizontalSliderSlide, HorizontalSliderSpacer, VerticalSliderSlide } from '~/components/ui'
import { useFilterContext } from '~/contexts'
import { css, font, shape, size, space } from '~/libs'

type ResolvedContext = Exclude<ReturnType<typeof useFilterContext>, undefined>
type PresenterProps = { commit: (value: string) => void, ctx: ResolvedContext, defs: { title: string }[] }

export function FilterFeatures() {
  const ctx = useFilterContext()
  if (!ctx) throw new Error('ctx is not defined')
  const defs = [
    { title: '保護者参加' },
    { title: '体験授業' },
    { title: 'オリジナルテキスト' },
    { title: '駅近く' },
    { title: '完全マンツーマン指導' },
    { title: '自習室完備' },
    { title: '送迎あり' },
    { title: '電話質問' },
    { title: '添削サービス' }
  ]
  const commit = (value: string) => ctx.commit(ctx.features, ctx.setFeatures, value)
  return <Presenter commit={commit} ctx={ctx} defs={defs} />
}

function Presenter({ commit, ctx, defs }: PresenterProps) {
  const styled = {
    button: {
      commit: css({
        ...font({ color: '#222', fontSize: '14px', fontWeight: 400, lineHeight: '18px' }),
        ...shape({ backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '30px', padding: '10px 20px' }),
        ...space({ marginRight: '10px' })
      })
    },
    root: css({
      ...shape({ padding: '24px 0' })
    }),
    spacer: {
      first: css({
        ...size({ width: '24px' })
      }),
      last: css({
        ...size({ width: `${24 - 10}px` })
      })
    },
    text: {
      title: css({
        ...font({ color: '#222', fontSize: '18px', fontWeight: 600, lineHeight: '22px' }),
        ...shape({ padding: '0 24px' }),
        ...space({ marginBottom: '24px' })
      })
    }
  }
  const selected = {
    button: {
      commit: css({
        ...font({ color: '#fff' }),
        ...shape({ backgroundColor: '#222', borderColor: '#222' })
      })
    }
  }
  return (
    <VerticalSliderSlide class={styled.root}>
      <H3 class={styled.text.title}>特色から探す</H3>
      <HorizontalSlider>
        <HorizontalSliderSpacer class={styled.spacer.first} />
        <For each={defs}>
          {({ title }) => (
            <HorizontalSliderSlide>
              <Button
                class={styled.button.commit}
                classList={{ [selected.button.commit]: ctx.has(ctx.features, title) }}
                onClick={() => commit(title)}
              >
                {title}
              </Button>
            </HorizontalSliderSlide>
          )}
        </For>
        <HorizontalSliderSpacer class={styled.spacer.last} />
      </HorizontalSlider>
    </VerticalSliderSlide>
  )
}
