import { Button, Div, H2, Header } from '~/components/html'
import { RiSystemCloseLine } from '~/components/icons'
import { useRootContext } from '~/contexts'
import { css, font, layout, shape, size } from '~/libs'

type PresenterProps = { close: () => void }

export function SearchToolbar() {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const close = () => ctx.drawer1()?.moveToIdx(0, true, { duration: 0 }) // no animation
  return <Presenter close={close} />
}

function Presenter({ close }: PresenterProps) {
  const styled = {
    button: {
      action: css({
        ...layout({ display: 'grid', placeContent: 'center' }),
        ...shape({ backgroundColor: '#ffffffe5', border: '1px solid #b0b0b0', borderRadius: '100%' }),
        ...size({ height: '32px', width: '32px' })
      })
    },
    root: css({
      ...layout({ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }),
      ...shape({ padding: '18px 20px 10px' })
    }),
    spacer: css({
      ...size({ width: '32px' })
    }),
    text: {
      title: css({
        ...font({ color: '#222', fontSize: '16px', fontWeight: 600, lineHeight: '20px' })
      })
    }
  }
  return (
    <Header class={styled.root}>
      <Button aria-label='close' class={styled.button.action} onClick={close}>
        <RiSystemCloseLine color='#222' size={16} />
      </Button>
      <H2 class={styled.text.title}>現在地以外から探す</H2>
      <Div class={styled.spacer} />
    </Header>
  )
}
