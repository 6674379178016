import { Match, Switch } from 'solid-js'
import { Button, Div } from '~/components/html'
import { AiTwotoneHeart } from '~/components/icons'
import { useFavoriteContext } from '~/contexts'
import { css, layout, position, shape, size, space } from '~/libs'

type Props = { id: number }
type PresenterProps = Props & {
  ctx: Exclude<ReturnType<typeof useFavoriteContext>, undefined>
  fav: (ref: MouseEvent & { currentTarget: HTMLButtonElement, target: Element }) => void
}

export function RoomListItemToolbar(props: Props) {
  const ctx = useFavoriteContext()
  if (!ctx) throw new Error('ctx is not defined')
  const fav = (ref: MouseEvent & { currentTarget: HTMLButtonElement, target: Element }) => ctx.fav(ref, props.id)
  return <Presenter {...props} ctx={ctx} fav={fav} />
}

function Presenter({ ctx, fav, id }: PresenterProps) {
  const styled = {
    button: {
      action: css({
        ...layout({ display: 'flex' }),
        ...shape({ padding: '4px' }),
        ...space({ margin: '-4px' })
      })
    },
    root: css({
      ...layout({ display: 'grid', placeContent: 'center' }),
      ...position({ position: 'absolute', right: '12px', top: '12px' }),
      ...size({ height: '32px', width: '32px' })
    })
  }
  return (
    <Div class={styled.root}>
      <Button aria-label='add or remove favorite' class={styled.button.action} onClick={fav}>
        <Switch>
          <Match when={ctx.has(id)}>
            <AiTwotoneHeart color='#ff385c' size={24} />
          </Match>
          <Match when={!ctx.has(id)}>
            <AiTwotoneHeart color='rgba(0, 0, 0, .5)' size={24} />
          </Match>
        </Switch>
      </Button>
    </Div>
  )
}
