import { Match, Switch } from 'solid-js'
import { Button, Div } from '~/components/html'
import { Skeleton } from '~/components/ui'
import { useFilterContext, useRootContext } from '~/contexts'
import { css, font, layout, position, shape, size } from '~/libs'

type PresenterProps = {
  clear: () => void
  close: () => void
  rctx: Exclude<ReturnType<typeof useRootContext>, undefined>
}

export function FilterNavigationBar() {
  const rctx = useRootContext()
  if (!rctx) throw new Error('ctx is not defined')
  const fctx = useFilterContext()
  if (!fctx) throw new Error('fctx is not defined')
  const clear = () => fctx.clear()
  const close = () => rctx.drawer3()?.moveToIdx(0)
  return <Presenter clear={clear} close={close} rctx={rctx} />
}

function Presenter({ clear, close, rctx }: PresenterProps) {
  const styled = {
    button: {
      clear: css({
        ...font({ color: '#222', fontSize: '16px', fontWeight: 600, lineHeight: '20px', textDecoration: 'underline' })
      }),
      close: css({
        ...font({ color: '#fff', fontSize: '16px', fontWeight: 600, lineHeight: '20px' }),
        ...shape({ backgroundColor: '#222', borderRadius: '8px', padding: '14px 24px' }),
        ...size({ height: '48px' })
      })
    },
    root: css({
      ...layout({ display: 'flex', justifyContent: 'space-between' }),
      ...position({ bottom: 0, position: 'sticky' }),
      ...shape({ backgroundColor: '#fff', borderTop: '1px solid #ebebeb', padding: '16px 24px' })
    })
  }
  return (
    <Div class={styled.root}>
      <Button class={styled.button.clear} onClick={clear}>すべてクリア</Button>
      <Button class={styled.button.close} onClick={close}>
        <Switch>
          <Match when={!rctx.store()}>
            <Skeleton />
          </Match>
          <Match keyed when={rctx.store()}>
            {store => (
              <Switch>
                <Match when={store.length}>
                  {store.length}件の周辺の教室を表示
                </Match>
                <Match when={!store.length}>
                  教室がない？
                </Match>
              </Switch>
            )}
          </Match>
        </Switch>
      </Button>
    </Div>
  )
}
