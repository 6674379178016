type Props = ComponentProps<'div'>
type PresenterProps = Props

export function ListView(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ children }: PresenterProps) {
  return <>{children}</>
}
