import { Button, Div, H2, Header } from '~/components/html'
import { RiSystemCloseLine } from '~/components/icons'
import { useRootContext } from '~/contexts'
import { css, font, layout, shape, size, space } from '~/libs'

type PresenterProps = { close: () => void }

export function FilterToolbar() {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const close = () => ctx.drawer3()?.moveToIdx(0)
  return <Presenter close={close} />
}

function Presenter({ close }: PresenterProps) {
  const styled = {
    button: {
      action: css({
        ...layout({ display: 'flex' }),
        ...shape({ padding: '8px' }),
        ...space({ margin: '-8px' })
      })
    },
    root: css({
      ...layout({ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }),
      ...shape({ borderBottom: '1px solid #ebebeb', padding: '0 24px' }),
      ...size({ height: '48px' })
    }),
    spacer: css({
      ...size({ width: '16px' })
    }),
    text: {
      title: css({
        ...font({ color: '#222', fontSize: '16px', fontWeight: 600, lineHeight: '20px' })
      })
    }
  }
  return (
    <Header class={styled.root}>
      <Button aria-label='close' class={styled.button.action} onClick={close}>
        <RiSystemCloseLine color='#222' size={16} />
      </Button>
      <H2 class={styled.text.title}>フィルター</H2>
      <Div class={styled.spacer} />
    </Header>
  )
}
