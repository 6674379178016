import cover from '~/assets/resize/252x252.png'
import { Div, Img } from '~/components/html'
import { css, shape, size } from '~/libs'

export function RichResultsCover() {
  const styled = {
    root: css({
      ...shape({ backgroundColor: '#f7f7f7', borderBottomLeftRadius: '12px', borderTopLeftRadius: '12px' }),
      ...size({ height: 'inherit' }),
      '& > img': {
        ...shape({ borderBottomLeftRadius: 'inherit', borderTopLeftRadius: 'inherit' })
      }
    })
  }
  return (
    <Div class={styled.root}>
      <Img height={126} src={cover} width={126} />
    </Div>
  )
}
