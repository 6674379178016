import { mode as MODE } from '@api/routes/geolocation'
import { createResource, onCleanup } from 'solid-js'
import { useLocation } from 'solid-start'
import { parse } from 'tyqs'
import Cookies from 'universal-cookie'
import { Div } from '~/components/html'
import { Async } from '~/components/ui'
import { useRootContext } from '~/contexts'
import { api } from '~/entry-api'
import { css, layout, mapboxgl, position, shape, size } from '~/libs'

type Payload = Parameters<ReturnType<typeof api>['geolocation']['v1']['coordinate']['$post']>[0]['json']
type ResolvedData = Awaited<ReturnType<typeof fetcher>>
type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type PresenterProps = { ctx: ResolvedContext, data: ResolvedData }
type RendererProps = PresenterProps & { ref: HTMLDivElement }

const fetcher = (payload: Payload) => api().geolocation.v1.coordinate.$post({ json: payload }).then(res => res.json())

export function Mapbox() {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const transform = (value: string | string[]) => !isNaN(Number(value)) ? Number(value) : undefined
  const search = useLocation().search
  const params = parse<{ lat?: number, lng?: number, mode?: number, pin?: number, radius?: number, zoom?: number }>(
    search,
    ({ value }) => transform(value)
  )
  const cookies = new Cookies()
  const [lat, lng, mode, radius, zoom, pin] = [
    params.lat ?? transform(cookies.get('lat')),
    params.lng ?? transform(cookies.get('lng')),
    params.mode ?? transform(cookies.get('mode')),
    params.radius ?? ctx.radius(),
    params.zoom ?? ctx.zoom(),
    params.pin ?? ctx.pin()
  ]
  const [data] = createResource({ lat, lng, mode, pin, radius, zoom }, fetcher)
  onCleanup(() => ctx.map()?.remove())
  return <Async component={data => <Presenter ctx={ctx} data={data} />} data={data} />
}

function Presenter({ ctx, data }: PresenterProps) {
  const styled = {
    root: css({
      ...position({ zIndex: 1 }),
      ...shape({ opacity: 0 }),
      ...size({ height: '100%', width: '100%' }),
      '& > .mapboxgl-canvas-container': {
        ...size({ height: '100%' })
      },
      '& > .mapboxgl-control-container': {
        ...position({ bottom: '74px', position: 'absolute' }),
        ...size({ width: '100%' })
      }
    })
  }
  const mounted = {
    root: css({
      ...shape({ opacity: 1 })
    })
  }
  const modechanged = {
    root: css({
      ...layout({ display: 'none' })
    })
  }
  return (
    <Div
      class={styled.root}
      classList={{ [mounted.root]: typeof ctx.map() !== 'undefined', [modechanged.root]: ctx.mode() === MODE.list }}
      ref={ref => renderer({ ctx, data, ref })}
    />
  )
}

const { VITE_MAPBOX_ACCESS_TOKEN, VITE_MAPBOX_STYLE_URL } = import.meta.env

const renderer = ({ ctx, data, ref }: RendererProps) => {
  const opts: mapboxgl.MapboxOptions = {
    accessToken: VITE_MAPBOX_ACCESS_TOKEN,
    center: { lat: data.lat, lng: data.lng },
    container: ref,
    // mapbox://styles/mapbox/light-v11
    style: `mapbox://styles/${VITE_MAPBOX_STYLE_URL}`,
    zoom: data.zoom
  }
  const render = () => {
    new mapboxgl.Map(opts)
      .on('load', ({ target }) => {
        ctx.setCenter(target.getCenter())
        ctx.setRadius(data.radius)
        ctx.setZoom(data.zoom)
        ctx.setMode(data.mode)
        ctx.setPin(data.pin)
        ctx.setMap(target)
      })
      .on('movestart', () => {
        ctx.setLoading(true)
      })
      .on('moveend', ({ target }) => {
        ctx.setCenter(target.getCenter()) // request fire
      })
      .on('zoomend', ({ target }) => {
        ctx.setZoom(target.getZoom())
      })
  }
  requestAnimationFrame(render)
}
