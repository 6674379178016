import { mode as MODE } from '@api/routes/geolocation'
import { createEffect, on } from 'solid-js'
import { Div } from '~/components/html'
import { useRootContext } from '~/contexts'
import { KeenSlider, css, layout, position, shape, size, ux } from '~/libs'

type Props = ComponentProps<'div'>
type PresenterProps = Props & { mount: (ref: HTMLDivElement) => void }

export function BottomSheet(props: Props) {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const mount = async (ref: HTMLDivElement) => {
    await new Promise(resolve => setTimeout(() => resolve(true), 0)) // await 1 tick
    const drawer = new KeenSlider(ref, { slides: { perView: 1 }, vertical: true })
    drawer.on('slideChanged', e => {
      if (e.track.details.rel === 0) return ctx.setMode(MODE.map)
      if (ctx.mode() === MODE.map && e.track.details.rel === 1) return setTimeout(() => ctx.setMode(MODE.list), 200)
      if (e.track.details.rel === 2) return ctx.setMode(MODE.infer)
    })
    ctx.setDrawer4(drawer)
  }
  createEffect(on([ctx.drawer4, ctx.mode], ([drawer, mode]) => {
    if (!drawer || typeof mode === 'undefined') return
    if (mode === MODE.list) return drawer.moveToIdx(1)
    if (mode === MODE.favorite) return drawer.moveToIdx(1, true, { duration: 0 }) // no animation
    if (mode === MODE.infer) return drawer.moveToIdx(0)
  }, { defer: true }))
  return <Presenter {...props} mount={mount} />
}

function Presenter({ children, mount }: PresenterProps) {
  const styled = {
    box: css({
      ...size({ height: '100%' }),
      '& + div': {
        ...shape({ backgroundColor: '#fff' })
      }
    }),
    container: css({
      ...size({ height: '100%' }),
      '& > div:first-child': {
        ...layout({ flex: 'none' })
      }
    }),
    root: css({
      ...position({ position: 'absolute', zIndex: 2 }),
      ...size({ height: '100%', width: '100%' }),
      ...ux({ pointerEvents: 'none' })
    })
  }
  return (
    <Div class={styled.root}>
      <Div class={`${styled.container} keen-slider`} ref={mount}>
        <Div class={`${styled.box} keen-slider__slide`}>{children}</Div>
        <Div class={`${styled.box} keen-slider__slide`} />
      </Div>
    </Div>
  )
}
