import { For } from 'solid-js'
import { Button, Div, H3 } from '~/components/html'
import {
  RiUserFacesGroup2Line,
  RiUserFacesParentLine,
  RiUserFacesTeamLine,
  RiUserFacesUser2Line
} from '~/components/icons'
import { VerticalSliderSlide } from '~/components/ui'
import { useFilterContext } from '~/contexts'
import { css, font, layout, position, shape, size, space } from '~/libs'

type ResolvedContext = Exclude<ReturnType<typeof useFilterContext>, undefined>
type PresenterProps = {
  commit: (value: string) => void
  ctx: ResolvedContext
  defs: { Icon: IconTypes, title: string }[]
}

export function FilterTypes() {
  const ctx = useFilterContext()
  if (!ctx) throw new Error('ctx is not defined')
  const defs = [
    { Icon: RiUserFacesTeamLine, title: '集団指導' },
    { Icon: RiUserFacesParentLine, title: '個別指導' },
    { Icon: RiUserFacesUser2Line, title: '家庭教師' },
    { Icon: RiUserFacesGroup2Line, title: '通信・ネット' }
  ]
  const commit = (value: string) => ctx.commit(ctx.types, ctx.setTypes, value)
  return <Presenter commit={commit} ctx={ctx} defs={defs} />
}

function Presenter({ commit, ctx, defs }: PresenterProps) {
  const styled = {
    button: {
      commit: css({
        ...font({ color: '#222', fontSize: '16px', fontWeight: 600, lineHeight: '20px' }),
        ...layout({ display: 'flex', flexFlow: 'column', justifyContent: 'space-between' }),
        ...shape({ backgroundColor: '#fff', border: '1px solid #b0b0b080', borderRadius: '16px', padding: '16px' }),
        ...size({ height: '130px' })
      })
    },
    container: css({
      ...layout({
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(2, auto)'
      }),
      ...space({ gap: '16px' })
    }),
    root: css({
      ...shape({ padding: '24px' }),
      '&::after': {
        ...position({ bottom: 0, content: '""', left: '24px', position: 'absolute' }),
        ...size({ height: '1px', width: 'calc(100% - 48px)' }),
        ...shape({ backgroundColor: '#ebebeb' })
      }
    }),
    text: {
      title: css({
        ...font({ color: '#222', fontSize: '18px', fontWeight: 600, lineHeight: '22px' }),
        ...space({ marginBottom: '24px' })
      })
    }
  }
  const selected = {
    button: {
      commit: css({
        ...shape({ backgroundColor: '#f7f7f7 !important', borderColor: '#222 !important' })
      })
    }
  }
  return (
    <VerticalSliderSlide class={styled.root}>
      <H3 class={styled.text.title}>受講タイプ</H3>
      <Div class={styled.container}>
        <For each={defs}>
          {({ Icon, title }) => (
            <Button
              class={styled.button.commit}
              classList={{ [selected.button.commit]: ctx.has(ctx.types, title) }}
              onClick={() => commit(title)}
            >
              <Icon color='#222' size={32} />
              {title}
            </Button>
          )}
        </For>
      </Div>
    </VerticalSliderSlide>
  )
}
