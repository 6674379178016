import { ErrorBoundary } from 'solid-js'
import { Main, Meta, Title } from '~/components/html'
import {
  AddressDrawer,
  AddressSearch,
  AddressToolbar,
  BottomSheet,
  FavoriteDrawer,
  FavoriteList,
  FeatureSlides,
  FilterDrawer,
  FilterList,
  FilterNavigationBar,
  FilterToolbar,
  ListDrawer,
  ListView,
  Loading,
  Mapbox,
  MapboxPins,
  PullHeader,
  RadiusCircleWithPin,
  ReturnMap,
  RichResults,
  RoomList,
  SearchAddress,
  SearchBar,
  SearchBarAddress,
  SearchBarFilter,
  SearchDrawer,
  SearchNavigationBar,
  SearchToolbar,
  Tabbar,
  Toolbar,
  YoureHere
} from '~/components/routes/root'
import { Fallback, VirtualLayout, VirtualScrollbar } from '~/components/ui'
import {
  FavoriteContextProvider,
  FilterContextProvider,
  RequestedContextProvider,
  RootContextProvider,
  VirtualContextProvider
} from '~/contexts'
import { css } from '~/libs'

export default function Page() {
  const styled = {
    root: css({ WebkitFontSmoothing: 'antialiased' })
  }
  return (
    <>
      <Title>イージー＆スピーディーに教室を検索 - Concier109</Title>
      <Meta content='イージー＆スピーディーに教室を検索 - Concier109' name='description' />
      <Meta content='app-id=6473833775' name='apple-itunes-app' />
      <Main class={styled.root}>
        <ErrorBoundary fallback={(error: Error) => <Fallback {...error} />}>
          <VirtualContextProvider>
            <VirtualLayout isRoot>
              <RootContextProvider>
                <RequestedContextProvider>
                  <FavoriteContextProvider>
                    <FilterContextProvider>
                      <Presenter />
                    </FilterContextProvider>
                  </FavoriteContextProvider>
                </RequestedContextProvider>
              </RootContextProvider>
            </VirtualLayout>
            <VirtualScrollbar />
          </VirtualContextProvider>
        </ErrorBoundary>
      </Main>
    </>
  )
}

function Presenter() {
  return (
    <>
      <Toolbar>
        <SearchBar>
          <SearchBarAddress />
          <SearchBarFilter />
        </SearchBar>
        <FeatureSlides />
      </Toolbar>
      <Loading />
      <YoureHere />
      <Mapbox />
      <RadiusCircleWithPin />
      <MapboxPins />
      <RichResults />
      <BottomSheet>
        <ListDrawer>
          <PullHeader />
        </ListDrawer>
      </BottomSheet>
      <ListView>
        <RoomList />
        <ReturnMap />
        <Tabbar />
      </ListView>
      <SearchDrawer>
        <SearchToolbar />
        <SearchAddress />
        <SearchNavigationBar />
      </SearchDrawer>
      <AddressDrawer>
        <AddressToolbar />
        <AddressSearch />
      </AddressDrawer>
      <FilterDrawer>
        <FilterToolbar />
        <FilterList />
        <FilterNavigationBar />
      </FilterDrawer>
      <FavoriteDrawer>
        <FavoriteList />
      </FavoriteDrawer>
    </>
  )
}
