import { createEffect, createMemo, createResource, createSignal, on } from 'solid-js'
import { Div, Input, Label, Span } from '~/components/html'
import { RiSystemSearchLine } from '~/components/icons'
import { VerticalSliderSlide } from '~/components/ui'
import { useRootContext } from '~/contexts'
import { api } from '~/entry-api'
import { css, font, layout, shape, size, space } from '~/libs'

type Payload = Parameters<ReturnType<typeof api>['geocoding']['v1']['forward']['$post']>[0]['json']
type PresenterProps = {
  handleInput: (
    e:
      | InputEvent & { currentTarget: HTMLInputElement, target: Element }
      | CompositionEvent & { currentTarget: HTMLInputElement, target: Element }
  ) => void
  setIsComposing: Setter<boolean>
}

const fetcher = (payload: Payload) => api().geocoding.v1.forward.$post({ json: payload }).then(res => res.json())

export function AddressSearchInput() {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const [isComposing, setIsComposing] = createSignal(false) // IME transform timing
  const [query, setQuery] = createSignal<string>()
  const handleInput = (
    e:
      | InputEvent & { currentTarget: HTMLInputElement, target: Element }
      | CompositionEvent & { currentTarget: HTMLInputElement, target: Element }
  ) => {
    if (isComposing()) return
    setQuery(e.currentTarget.value)
  }
  const payload = createMemo(() => {
    const q = query() ?? ''
    return q.length >= 2 ? { query: q } : undefined
  })
  const [data] = createResource(payload, payload => {
    if (payload) return fetcher(payload)
    return Promise.resolve(null)
  })
  createEffect(on(data, data => {
    if (!data) return
    ctx.setFeatures(data.result.features)
  }, { defer: true }))
  return <Presenter handleInput={handleInput} setIsComposing={setIsComposing} />
}

function Presenter({ handleInput, setIsComposing }: PresenterProps) {
  const styled = {
    box: css({
      ...shape({
        backgroundColor: '#fff',
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
        boxShadow: '0 0 0 1px rgb(0 0 0 / 4%), 0 6px 20px rgb(0 0 0 / 20%)',
        padding: '24px 24px 16px'
      })
    }),
    container: css({
      ...shape({ paddingTop: '15px' })
    }),
    icon: css({
      ...layout({ display: 'flex' })
    }),
    input: css({
      ...layout({ flex: 1 }),
      ...font({ color: '#717171', fontSize: '16px', /* 14px */ fontWeight: 400, lineHeight: '18px' })
    }),
    label: css({
      ...layout({ alignItems: 'center', display: 'flex' }),
      ...shape({ backgroundColor: '#f7f7f7', borderRadius: '12px', padding: '0 20px' }),
      ...size({ height: '60px' }),
      ...space({ columnGap: '12px', marginTop: '16px' })
    })
  }
  return (
    <VerticalSliderSlide class={styled.container}>
      <Div class={styled.box}>
        <Label class={styled.label}>
          <Span class={styled.icon}>
            <RiSystemSearchLine size={16} />
          </Span>
          <Input
            class={styled.input}
            name='landmark'
            onCompositionEnd={e => {
              setIsComposing(false)
              handleInput(e)
            }}
            onCompositionStart={() => setIsComposing(true)}
            onInput={handleInput}
            placeholder='住所やランドマークから検索'
          />
        </Label>
      </Div>
    </VerticalSliderSlide>
  )
}
