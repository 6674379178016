import { Show } from 'solid-js'
import { Button, Div } from '~/components/html'
import { RiMediaEqualizerLine } from '~/components/icons'
import { useFilterContext, useRootContext } from '~/contexts'
import { css, layout, position, shape, size } from '~/libs'

type PresenterProps = { fctx: Exclude<ReturnType<typeof useFilterContext>, undefined>, open: () => void }

export function SearchBarFilter() {
  const rctx = useRootContext()
  if (!rctx) throw new Error('rctx is not defined')
  const fctx = useFilterContext()
  if (!fctx) throw new Error('fctx is not defined')
  const open = () => {
    rctx.setIsOpenFilter(true)
    rctx.drawer3()?.moveToIdx(1)
  }
  return <Presenter fctx={fctx} open={open} />
}

function Presenter({ fctx, open }: PresenterProps) {
  const styled = {
    badge: css({
      ...position({ position: 'absolute', right: '-6px', top: '-6px' }),
      ...shape({ backgroundColor: '#222', border: '2px solid #fff', borderRadius: '50%' }),
      ...size({ height: '20px', width: '20px' })
    }),
    button: {
      open: css({
        ...layout({ display: 'inherit', placeContent: 'inherit' }),
        ...position({ position: 'relative' }),
        ...shape({ border: '1px solid #b0b0b0', borderRadius: '50%' }),
        ...size({ height: '40px', width: '40px' })
      })
    },
    root: css({
      ...layout({ display: 'grid', placeContent: 'center' }),
      ...size({ width: '60px' })
    })
  }
  const selected = {
    button: {
      open: css({
        ...shape({ border: '2px solid #222' })
      })
    }
  }
  return (
    <Div class={styled.root}>
      <Button
        aria-label='open'
        class={styled.button.open}
        classList={{ [selected.button.open]: fctx.hasCond() }}
        onClick={open}
      >
        <Show when={fctx.hasCond()}>
          <Div class={styled.badge} />
        </Show>
        <RiMediaEqualizerLine color='#222' size={16} />
      </Button>
    </Div>
  )
}
