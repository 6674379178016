import { mode as MODE } from '@api/routes/geolocation'
import Headroom from 'headroom.js'
import { Match, Show, Switch, createEffect, on } from 'solid-js'
import { Button, Div } from '~/components/html'
import { AiOutlineHeart, RiSystemSearchLine } from '~/components/icons'
import { useRootContext, useVirtualContext } from '~/contexts'
import { animation, css, font, layout, position, shape, size } from '~/libs'

type Props = ComponentProps<'div'>
type PresenterProps = Props & {
  close: () => void
  mount: (ref: HTMLDivElement) => void
  open: () => void
  rctx: Exclude<ReturnType<typeof useRootContext>, undefined>
}

export function Tabbar(props: Props) {
  const vctx = useVirtualContext()
  if (!vctx) throw new Error('vctx is not defined')
  const rctx = useRootContext()
  if (!rctx) throw new Error('rctx is not defined')
  const mount = (ref: HTMLDivElement) => {
    const headroom = new Headroom(ref, { scroller: vctx.scrollElement() })
    headroom.init()
  }
  const close = () => {
    rctx.setIsOpenFavorite(false)
    rctx.drawer5()?.moveToIdx(0, true, { duration: 0 }) // no animation
    rctx.setMode(MODE.list)
  }
  const open = () => {
    rctx.setIsOpenFavorite(true)
    rctx.drawer5()?.moveToIdx(1, true, { duration: 0 }) // no animation
    rctx.setMode(MODE.favorite)
  }
  // actions after transitions, not user actions
  let isRestore = false
  createEffect(on(rctx.mode, mode => {
    if (typeof mode === 'undefined') isRestore = true
    if (isRestore && mode === MODE.favorite) {
      isRestore = false
      open()
    }
  }, { defer: false }))
  return <Presenter close={close} mount={mount} open={open} rctx={rctx} {...props} />
}

function Presenter({ close, mount, open, rctx }: PresenterProps) {
  const styled = {
    container: css({
      ...layout({ display: 'grid', gridAutoFlow: 'column', gridTemplateColumns: 'repeat(2, 1fr)' }),
      ...size({ height: '44px', width: '100%' })
    }),
    icon: css({
      ...layout({ display: 'flex', justifyContent: 'center' }),
      ...size({ height: '30px', width: '30px' })
    }),
    root: css({
      ...layout({ display: 'grid', placeItems: 'center' }),
      ...position({ bottom: 0, position: 'absolute', zIndex: 6 }),
      ...shape({ backgroundColor: '#fff', borderTop: '1px solid #ebebeb' }),
      ...size({ height: '64px', width: '100%' }),
      '&.headroom': {
        ...animation({ transition: 'transform 200ms linear', willChange: 'transform' })
      },
      '&.headroom--pinned': {
        ...animation({ transform: 'translateY(0)' })
      },
      '&.headroom--unpinned': {
        ...animation({ transform: 'translateY(100%)' })
      }
    }),
    tab: css({
      ...font({ color: '#717171', fontSize: '10px', fontWeight: 600, lineHeight: '12px' }),
      ...layout({ alignItems: 'center', display: 'flex', flexFlow: 'column', justifyContent: 'center' })
    })
  }
  const cond = {
    root: css({
      ...layout({ display: 'none !important' })
    })
  }
  return (
    <Show when={rctx.mode() === MODE.list || rctx.mode() === MODE.favorite}>
      <Div
        class={`${styled.root} headroom`}
        classList={{ [cond.root]: rctx.isOpenFilter() || rctx.isOpenSearch() || rctx.isOpenAddress() }}
        ref={mount}
      >
        <Div class={styled.container}>
          <Button aria-label='close' class={styled.tab} onClick={close}>
            <Div class={styled.icon}>
              <Switch>
                <Match when={rctx.isOpenFavorite()}>
                  <RiSystemSearchLine color='#b0b0b0' size={24} />
                </Match>
                <Match when={!rctx.isOpenFavorite()}>
                  <RiSystemSearchLine color='#ff385c' size={24} />
                </Match>
              </Switch>
            </Div>
            探す
          </Button>
          <Button aria-label='open' class={styled.tab} onClick={open}>
            <Div class={styled.icon}>
              <Switch>
                <Match when={!rctx.isOpenFavorite()}>
                  <AiOutlineHeart color='#b0b0b0' size={24} />
                </Match>
                <Match when={rctx.isOpenFavorite()}>
                  <AiOutlineHeart color='#ff385c' size={24} />
                </Match>
              </Switch>
            </Div>
            お気に入り
          </Button>
        </Div>
      </Div>
    </Show>
  )
}
