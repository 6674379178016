import { For, Match, Show, Switch, createResource } from 'solid-js'
import animationData from '~/assets/Animation - 1697448592147.json'
import { Div, H2 } from '~/components/html'
import { FavoriteListItems } from '~/components/routes/root'
import { Async, VerticalSlider, VerticalSliderSlide, VerticalSliderSpacer } from '~/components/ui'
import { useFavoriteContext, useRootContext, useVirtualContext } from '~/contexts'
import { api } from '~/entry-api'
import { chunk, css, font, layout, position, shape, size, space } from '~/libs'

type Payload = Parameters<ReturnType<typeof api>['rooms']['v1']['favorite']['$post']>[0]['json']
type ResolvedData = Awaited<ReturnType<typeof fetcher>>
type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type PresenterProps = { data: ResolvedData, rctx: ResolvedContext, render: (ref: HTMLDivElement) => void }

const fetcher = (payload: Payload) => api().rooms.v1.favorite.$post({ json: payload }).then(res => res.json())

export function FavoriteList() {
  const vctx = useVirtualContext()
  if (!vctx) throw new Error('vctx is not defined')
  const rctx = useRootContext()
  if (!rctx) throw new Error('rctx is not defined')
  const fctx = useFavoriteContext()
  if (!fctx) throw new Error('fctx is not defined')
  const payload = fctx.ids
  const [data] = createResource(payload, fetcher)
  const render = async (ref: HTMLDivElement) => {
    const lottie = await import('lottie-web').then(module => module.default)
    lottie.loadAnimation({ animationData, autoplay: true, container: ref, loop: true, renderer: 'svg' })
    const slider = vctx.slider()
    if (!slider) return
    vctx.update(slider)
  }
  return <Async component={data => <Presenter data={data} rctx={rctx} render={render} />} data={data} />
}

function Presenter({ data, rctx, render }: PresenterProps) {
  const styled = {
    box: css({
      ...shape({ padding: '24px' })
    }),
    lottie: css({
      ...position({ position: 'relative' }),
      ...shape({ paddingTop: `${290 / 426 * 100}%` }), // svg size
      ...size({ width: '100%' }),
      '& > div': {
        ...layout({ display: 'flex' }),
        ...position({ left: 0, position: 'absolute', top: 0 })
      }
    }),
    spacer: {
      0: css({
        ...size({ height: `${16 + 1}px` })
      }),
      1: css({
        ...size({ height: '1px' })
      })
    },
    text: {
      notfound: css({
        ...shape({ padding: '24px' }),
        ...font({ color: '#222', fontSize: '37px', fontWeight: 600, lineHeight: '45px' })
      }),
      title: css({
        ...font({ color: '#222', fontSize: '32px', fontWeight: 600, lineHeight: '36px' }),
        ...shape({ padding: '48px 24px 0' }),
        ...space({ marginBottom: '16px' })
      })
    }
  }
  return (
    <Show when={rctx.isOpenFavorite()}>
      <VerticalSlider>
        <VerticalSliderSlide>
          <H2 class={styled.text.title}>お気に入り💙</H2>
        </VerticalSliderSlide>
        <Switch>
          <Match when={data.length}>
            <For each={chunk(data, 2)}>
              {items => (
                <FavoriteListItems items={items} />
              )}
            </For>
            <VerticalSliderSpacer class={styled.spacer[0]} />
          </Match>
          <Match when={!data.length}>
            <VerticalSliderSlide>
              <Div class={styled.box}>
                <Div class={styled.lottie}>
                  <Div ref={render} />
                </Div>
              </Div>
            </VerticalSliderSlide>
            <VerticalSliderSlide>
              <Div class={styled.text.notfound}>
                <Div>
                  困りました、まだお気に入りがないようです。
                </Div>
              </Div>
            </VerticalSliderSlide>
            <VerticalSliderSpacer class={styled.spacer[1]} />
          </Match>
        </Switch>
      </VerticalSlider>
    </Show>
  )
}
