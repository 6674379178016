import { Div } from '~/components/html'
import { css, layout, shape } from '~/libs'

type Props = ComponentProps<'div'>
type PresenterProps = Props

export function SearchBar(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ children }: PresenterProps) {
  const styled = {
    root: css({
      ...layout({ display: 'flex' }),
      ...shape({ backgroundColor: '#fff', padding: '14px 14px 0 24px' })
    })
  }
  return (
    <Div class={styled.root}>
      {children}
    </Div>
  )
}
