import { Div } from '~/components/html'
import { css, position, shape, size } from '~/libs'

type Props = ComponentProps<'div'>
type PresenterProps = Props

export function Toolbar(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ children }: PresenterProps) {
  const styled = {
    root: css({
      ...position({ position: 'absolute', top: 0, zIndex: 4 }),
      ...shape({ backgroundColor: '#fff', boxShadow: 'rgba(0, 0, 0, .16) 0 0 6px' }),
      ...size({ width: '100%' })
    })
  }
  return <Div class={styled.root}>{children}</Div>
}
