import type { ReturnSearchResult } from '@api/routes/rooms'
import { useLocation } from 'solid-start'
import { A, Div, P } from '~/components/html'
import { RoomListItemCover, RoomListItemToolbar } from '~/components/routes/root'
import { VerticalSliderSlide } from '~/components/ui'
import { css, font, layout, position, shape, space } from '~/libs'

type Props = { data: ReturnSearchResult }
type PresenterProps = Props

export function RoomListItem(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ data }: PresenterProps) {
  const styled = {
    box: css({
      ...layout({ display: 'grid' }),
      ...space({ marginBottom: '40px', rowGap: '2px' })
    }),
    container: css({
      ...layout({ display: 'grid' }), // ios bugfix
      ...position({ position: 'relative' })
    }),
    root: css({
      ...shape({ padding: '0 24px' })
    }),
    text: {
      address: css({
        ...font({ color: '#717171', fontSize: '15px', fontWeight: 400, lineHeight: '19px' })
      }),
      features: css({
        ...font({ color: '#717171', fontSize: '15px', fontWeight: 400, lineHeight: '19px' })
      }),
      name: css({
        ...font({ color: '#222', fontSize: '15px', fontWeight: 600, lineHeight: '19px' })
      }),
      types: css({
        ...font({ color: '#717171', fontSize: '15px', fontWeight: 400, lineHeight: '19px' })
      })
    }
  }
  return (
    <VerticalSliderSlide class={styled.root}>
      <A class={styled.container} href={`rooms/${data.id}${useLocation().search}`}>
        <RoomListItemCover id={data.id} />
        <RoomListItemToolbar id={data.id} />
        <Div class={styled.box}>
          <P class={styled.text.name}>{data.name}</P>
          <P class={styled.text.types}>{data.types.join('・')}</P>
          <P class={styled.text.features}>{data.features.join('・')}</P>
          <P class={styled.text.address}>{data.address}</P>
        </Div>
      </A>
    </VerticalSliderSlide>
  )
}
