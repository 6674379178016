import { For, Match, Switch } from 'solid-js'
import { Button, Span } from '~/components/html'
import {
  RiMapBusLine,
  RiMapCarLine,
  RiMapRidingLine,
  RiMapRunLine,
  RiMapTrainLine,
  RiMapWalkLine
} from '~/components/icons'
import { HorizontalSlider, HorizontalSliderSlide, HorizontalSliderSpacer } from '~/components/ui'
import { useRootContext } from '~/contexts'
import { css, font, layout, position, shape, size, space } from '~/libs'

type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type PresenterProps = {
  ctx: ResolvedContext
  defs: { Icon: IconTypes, radius: number, title: string, zoom: number }[]
  set: (zoom: number, radius: number) => void
}

export function FeatureSlides() {
  const defs = [
    { Icon: RiMapWalkLine, radius: 0.2, title: '200m圏内', zoom: 16 },
    { Icon: RiMapRunLine, radius: 0.4, title: '400m圏内', zoom: 15 },
    { Icon: RiMapRidingLine, radius: 0.8, title: '800m圏内', zoom: 14 },
    { Icon: RiMapCarLine, radius: 1.6, title: '1.6km圏内', zoom: 13 },
    { Icon: RiMapBusLine, radius: 3.2, title: '3.2km圏内', zoom: 12 },
    { Icon: RiMapTrainLine, radius: 6.4, title: '6.4km圏内', zoom: 11 }
  ]
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const set = (zoom: number, radius: number) => {
    ctx.map()?.setZoom(zoom)
    ctx.setRadius(radius)
  }
  return <Presenter ctx={ctx} defs={defs} set={set} />
}

function Presenter({ ctx, defs, set }: PresenterProps) {
  const styled = {
    button: {
      action: css({
        ...layout({ display: 'grid', placeItems: 'center' }),
        ...position({ position: 'relative' }),
        ...shape({ padding: '4px 0 14px' }),
        ...size({ height: 'min-content', minWidth: '64px' }),
        ...space({ marginTop: '12px', rowGap: '4px' })
      })
    },
    container: css({
      ...shape({ paddingLeft: '16px' })
    }),
    spacer: {
      first: css({
        ...size({ width: '8px' })
      }),
      last: css({
        ...size({ width: '24px' })
      })
    },
    text: {
      title: css({
        ...font({ color: '#717171', fontSize: '12px', fontWeight: 600, lineHeight: '16px' })
      })
    }
  }
  const selected = {
    label: css({
      ...font({ color: '#222' }),
      '&::after': {
        ...position({ bottom: 0, content: '""', left: 0, position: 'absolute' }),
        ...shape({ backgroundColor: '#000' }),
        ...size({ height: '2px', width: '100%' })
      }
    })
  }
  return (
    <HorizontalSlider>
      <HorizontalSliderSpacer class={styled.spacer.first} />
      <For each={defs}>
        {({ Icon, radius, title, zoom }) => (
          <HorizontalSliderSlide class={styled.container}>
            <Button class={styled.button.action} onClick={() => set(zoom, radius)}>
              <Switch>
                <Match when={ctx.radius() === radius}>
                  <Icon color='#222' size={24} />
                </Match>
                <Match when={ctx.radius() !== radius}>
                  <Icon color='#717171' size={24} />
                </Match>
              </Switch>
              <Span class={styled.text.title} classList={{ [selected.label]: ctx.radius() === radius }}>{title}</Span>
            </Button>
          </HorizontalSliderSlide>
        )}
      </For>
      <HorizontalSliderSpacer class={styled.spacer.last} />
    </HorizontalSlider>
  )
}
