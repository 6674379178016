import { Show } from 'solid-js'
import { AddressSearchInput, AddressSearchResults } from '~/components/routes/root'
import { VerticalSlider } from '~/components/ui'
import { useRootContext } from '~/contexts'
import { css, position, shape, size } from '~/libs'

type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type PresenterProps = { ctx: ResolvedContext }

export function AddressSearch() {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  return <Presenter ctx={ctx} />
}

function Presenter({ ctx }: PresenterProps) {
  const styled = {
    root: css({
      ...position({ position: 'relative' }),
      '&:after': {
        ...position({ content: '""', left: 0, position: 'absolute', top: '131px', zIndex: -1 }),
        ...shape({ backgroundColor: '#fff' }),
        ...size({ height: '100%', width: '100%' })
      }
    })
  }
  return (
    <Show when={ctx.isOpenAddress()}>
      <VerticalSlider class={styled.root} space={60}>
        <AddressSearchInput />
        <AddressSearchResults />
      </VerticalSlider>
    </Show>
  )
}
