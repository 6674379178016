import { For } from 'solid-js'
import { Button, H3 } from '~/components/html'
import { HorizontalSlider, HorizontalSliderSlide, HorizontalSliderSpacer, VerticalSliderSlide } from '~/components/ui'
import { useFilterContext } from '~/contexts'
import { css, font, position, shape, size, space } from '~/libs'

type ResolvedContext = Exclude<ReturnType<typeof useFilterContext>, undefined>
type PresenterProps = {
  commit: (value: number) => void
  ctx: ResolvedContext
  defs: { title: string, value: number }[]
}

export function FilterRadius() {
  const ctx = useFilterContext()
  if (!ctx) throw new Error('ctx is not defined')
  const defs = [
    { title: '200m圏内', value: 0.2 },
    { title: '400m圏内', value: 0.4 },
    { title: '800m圏内', value: 0.8 },
    { title: '1.6km圏内', value: 1.6 },
    { title: '3.2km圏内', value: 3.2 },
    { title: '6.4km圏内', value: 6.4 }
  ]
  const commit = (value: number) => ctx.setRadius(value)
  return <Presenter commit={commit} ctx={ctx} defs={defs} />
}

function Presenter({ commit, ctx, defs }: PresenterProps) {
  const styled = {
    button: {
      commit: css({
        ...font({ color: '#222', fontSize: '14px', fontWeight: 400, lineHeight: '18px' }),
        ...shape({ backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '30px', padding: '10px 20px' }),
        ...space({ marginRight: '10px' })
      })
    },
    root: css({
      ...shape({ padding: '24px 0' }),
      '&::after': {
        ...position({ bottom: 0, content: '""', left: '24px', position: 'absolute' }),
        ...size({ height: '1px', width: 'calc(100% - 48px)' }),
        ...shape({ backgroundColor: '#ebebeb' })
      }
    }),
    spacer: {
      first: css({
        ...size({ width: '24px' })
      }),
      last: css({
        ...size({ width: `${24 - 10}px` })
      })
    },
    text: {
      title: css({
        ...font({ color: '#222', fontSize: '18px', fontWeight: 600, lineHeight: '22px' }),
        ...shape({ padding: '0 24px' }),
        ...space({ marginBottom: '24px' })
      })
    }
  }
  const selected = {
    button: {
      commit: css({
        ...font({ color: '#fff' }),
        ...shape({ backgroundColor: '#222', borderColor: '#222' })
      })
    }
  }
  return (
    <VerticalSliderSlide class={styled.root}>
      <H3 class={styled.text.title}>通学距離</H3>
      <HorizontalSlider>
        <HorizontalSliderSpacer class={styled.spacer.first} />
        <For each={defs}>
          {({ title, value }) => (
            <HorizontalSliderSlide>
              <Button
                class={styled.button.commit}
                classList={{ [selected.button.commit]: ctx.radius() === value }}
                onClick={() => commit(value)}
              >
                {title}
              </Button>
            </HorizontalSliderSlide>
          )}
        </For>
        <HorizontalSliderSpacer class={styled.spacer.last} />
      </HorizontalSlider>
    </VerticalSliderSlide>
  )
}
