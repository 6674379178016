import { Match, Show, Switch } from 'solid-js'
import { useLocation } from 'solid-start'
import { A, Button, Div, P } from '~/components/html'
import { AiFillHeart, AiOutlineHeart, RiSystemCloseLine } from '~/components/icons'
import { RichResultsCover } from '~/components/routes/root'
import { Requested } from '~/components/ui'
import { useFavoriteContext, useRootContext } from '~/contexts'
import { clamp, css, font, layout, position, shape, size, space } from '~/libs'

type PresenterProps = {
  fav: (ref: MouseEvent & { currentTarget: HTMLButtonElement, target: Element }, id: number) => void
  fctx: Exclude<ReturnType<typeof useFavoriteContext>, undefined>
  rctx: Exclude<ReturnType<typeof useRootContext>, undefined>
}

export function RichResults() {
  const rctx = useRootContext()
  if (!rctx) throw new Error('rctx is not defined')
  const fctx = useFavoriteContext()
  if (!fctx) throw new Error('fctx is not defined')
  const fav = (ref: MouseEvent & { currentTarget: HTMLButtonElement, target: Element }, id: number) => fctx.fav(ref, id)
  return <Presenter fav={fav} fctx={fctx} rctx={rctx} />
}

function Presenter({ fav, fctx, rctx }: PresenterProps, padding = 24) {
  const styled = {
    box: css({
      ...layout({ display: 'grid', gridTemplateRows: 'auto 1fr auto' }),
      ...position({ position: 'relative' }),
      ...shape({ padding: '12px' })
    }),
    button: {
      fav: css({
        ...layout({ display: 'flex' }),
        ...position({ bottom: '12px', position: 'absolute', right: '12px' }),
        ...shape({ padding: '8px' }),
        ...space({ margin: '-8px' })
      }),
      open: css({
        ...layout({ display: 'grid', placeContent: 'center' }),
        ...position({ left: `${padding}px`, position: 'absolute', top: 0, transform: 'translate(-50%, -50%)' }),
        ...shape({ backgroundColor: 'rgba(0, 0, 0, .4)', borderRadius: '50%' }),
        ...size({ height: '24px', width: '24px' })
      })
    },
    container: css({
      ...layout({ display: 'grid', gridAutoColumns: '126px 1fr', gridAutoFlow: 'column' }),
      ...shape({ backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 6px 20px rgba(0, 0, 0, .2)' }),
      ...size({ height: '126px', width: `calc(100% - ${padding * 2}px)` })
    }),
    root: css({
      ...layout({ display: 'flex', justifyContent: 'center' }),
      ...position({ bottom: '98px', position: 'absolute', zIndex: 2 }),
      ...size({ width: '100%' })
    }),
    text: {
      features: css({
        ...clamp(2),
        ...font({ color: '#717171', fontSize: '14px', fontWeight: 400, lineHeight: '18px' })
      }),
      name: css({
        ...clamp(2),
        ...font({ color: '#222', fontSize: '14px', fontWeight: 600, lineHeight: '18px' }),
        ...space({ marginBottom: '4px' })
      })
    }
  }
  return (
    <Show keyed when={rctx.store()}>
      {store => (
        <Show keyed when={rctx.pin()}>
          {id => (
            <Div class={styled.root}>
              <Button aria-label='open' class={styled.button.open} onClick={() => rctx.setPin(undefined)}>
                <RiSystemCloseLine color='#fff' size={16} />
              </Button>
              <A class={styled.container} href={`/rooms/${id}${useLocation().search}`}>
                <RichResultsCover />
                <Div class={styled.box}>
                  {(() => {
                    const data = store.find(data => data.id === id)!
                    return (
                      <>
                        <P class={styled.text.name}>{data.name}</P>
                        <P class={styled.text.features}>{data.features.join('・')}</P>
                        <Requested
                          id={data.id}
                          position={{ bottom: '9px', left: '12px', position: 'absolute' }}
                        />
                        <Button
                          aria-label='add or remove favorite'
                          class={styled.button.fav}
                          onClick={ref => fav(ref, id)}
                        >
                          <Switch>
                            <Match when={fctx.has(id)}>
                              <AiFillHeart color='#ff385c' size={16} />
                            </Match>
                            <Match when={!fctx.has(id)}>
                              <AiOutlineHeart color='#222' size={16} />
                            </Match>
                          </Switch>
                        </Button>
                      </>
                    )
                  })()}
                </Div>
              </A>
            </Div>
          )}
        </Show>
      )}
    </Show>
  )
}
