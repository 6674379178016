import { mode } from '@api/routes/geolocation'
import Headroom from 'headroom.js'
import { Show } from 'solid-js'
import { Button, Div } from '~/components/html'
import { RiMapMap2Fill } from '~/components/icons'
import { useRootContext, useVirtualContext } from '~/contexts'
import { animation, css, font, keyframes, layout, position, shape, size, space, ux } from '~/libs'

type Props = ComponentProps<'div'>
type PresenterProps = Props & {
  mount: (ref: HTMLDivElement) => void
  rctx: Exclude<ReturnType<typeof useRootContext>, undefined>
}

export function ReturnMap(props: Props) {
  const vctx = useVirtualContext()
  if (!vctx) throw new Error('vctx is not defined')
  const rctx = useRootContext()
  if (!rctx) throw new Error('rctx is not defined')
  const mount = (ref: HTMLDivElement) => {
    const headroom = new Headroom(ref, { scroller: vctx.scrollElement() })
    headroom.init()
  }
  return <Presenter mount={mount} rctx={rctx} {...props} />
}

function Presenter({ mount, rctx }: PresenterProps) {
  const anim = {
    root: keyframes({
      from: { ...shape({ opacity: 1 }) },
      to: { ...shape({ opacity: 0 }) }
    })
  }
  const styled = {
    button: {
      action: css({
        ...font({ color: '#fff', fontSize: '12px', fontWeight: 600, lineHeight: '16px' }),
        ...layout({ alignItems: 'center', display: 'flex', justifyContent: 'center' }),
        ...shape({ backgroundColor: '#222', borderRadius: '24px' }),
        ...size({ height: '40px', width: '100px' }),
        ...space({ columnGap: '8px' }),
        ...ux({ pointerEvents: 'auto' })
      })
    },
    root: css({
      ...layout({ display: 'grid', placeContent: 'center' }),
      ...position({ bottom: '64px', position: 'absolute', zIndex: 5 }),
      ...size({ height: '40px', width: '100%' }),
      ...space({ marginBottom: '24px' }),
      ...ux({ pointerEvents: 'none' }),
      '&.headroom': {
        ...animation({ transition: 'transform 200ms linear', willChange: 'transform' })
      },
      '&.headroom--pinned': {
        ...animation({ transform: 'translateY(0)' })
      },
      '&.headroom--unpinned': {
        ...animation({ transform: 'translateY(calc(100% + 24px))' })
      },
      '&:not(.headroom--top).headroom--bottom': {
        ...animation({ animation: `${anim.root} 200ms both ease-in-out` })
      }
    })
  }
  return (
    <Show when={rctx.mode() === mode.list}>
      <Div class={`${styled.root} headroom`} ref={mount}>
        <Button class={styled.button.action} onClick={() => rctx.setMode(mode.infer)}>
          マップ
          <RiMapMap2Fill color='#fff' size={16} />
        </Button>
      </Div>
    </Show>
  )
}
