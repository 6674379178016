import { Match, Switch } from 'solid-js'
import { Div } from '~/components/html'
import { Skeleton } from '~/components/ui'
import { useRootContext } from '~/contexts'
import { css, font, position, shape, size, ux } from '~/libs'

type Props = ComponentProps<'div'>
type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type PresenterProps = Props & { ctx: ResolvedContext }

export function PullHeader(props: Props) {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  return <Presenter ctx={ctx} {...props} />
}

function Presenter({ ctx }: PresenterProps) {
  const styled = {
    root: css({
      ...shape({ padding: '32px 24px 24px' }),
      ...font({ color: '#222', fontSize: '15px', fontWeight: 600, lineHeight: '18px', textAlign: 'center' }),
      ...ux({ userSelect: 'none' }),
      '&::before': {
        ...position({ content: '""', left: '50%', position: 'absolute', top: '8px', transform: 'translateX(-50%)' }),
        ...shape({ backgroundColor: 'rgba(32, 32, 32, .2)', borderRadius: '4px' }),
        ...size({ height: '4px', width: '40px' })
      }
    })
  }
  return (
    <Div class={styled.root}>
      <Switch>
        <Match when={!ctx.store()}>
          <Skeleton />
        </Match>
        <Match keyed when={ctx.store()}>
          {store => (
            <Switch>
              <Match when={store.length}>
                {store.length}件の周辺の教室
              </Match>
              <Match when={!store.length}>
                教室がない？ 検索条件を広げてみよう💙
              </Match>
            </Switch>
          )}
        </Match>
      </Switch>
    </Div>
  )
}
