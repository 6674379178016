import { HorizontalSliderSlide } from '~/components/ui'

type Props = ComponentProps<'div' | 'header' | 'section'>

export function HorizontalSliderSpacer(props: Props) {
  return (
    <HorizontalSliderSlide class={props.class ?? ''}>
      {props.children}
    </HorizontalSliderSlide>
  )
}
