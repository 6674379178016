import { Match, Switch, createMemo, createResource } from 'solid-js'
import { Button, Div } from '~/components/html'
import { RiSystemSearchLine } from '~/components/icons'
import { Skeleton } from '~/components/ui'
import { useRootContext } from '~/contexts'
import { api } from '~/entry-api'
import { clamp, css, font, layout, shape, size, space } from '~/libs'

type Payload = Parameters<ReturnType<typeof api>['geocoding']['v1']['reverse']['$post']>[0]['json']
type ResolvedData = Awaited<ReturnType<typeof fetcher>>
type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type PresenterProps = { ctx: ResolvedContext, data: Resource<ResolvedData>, open: () => void }

const fetcher = (payload: Payload) => api().geocoding.v1.reverse.$post({ json: payload }).then(res => res.json())

export function SearchBarAddress() {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const payload = createMemo(() => ({ lat: ctx.center()?.lat ?? 0, lng: ctx.center()?.lng ?? 0 }))
  const [data] = createResource(payload, fetcher)
  const open = () => {
    ctx.setIsOpenSearch(true)
    ctx.drawer1()?.moveToIdx(1, true, { duration: 0 }) // no animation
  }
  return <Presenter ctx={ctx} data={data} open={open} />
}

function Presenter({ ctx, data, open }: PresenterProps) {
  const styled = {
    box: css({
      ...layout({ display: 'grid', flex: 1 }),
      ...space({ marginRight: '24px' })
    }),
    button: {
      action: css({
        ...layout({ alignItems: 'center', display: 'flex' }),
        ...size({ height: '54px', width: '100%' })
      })
    },
    icon: css({
      ...layout({ display: 'flex', justifyContent: 'center' }),
      ...size({ width: '56px' })
    }),
    root: css({
      ...layout({ flex: 1 }),
      ...shape({
        backgroundColor: '#fff',
        border: '0.5px solid rgba(0, 0, 0, .08)',
        borderRadius: '100px',
        boxShadow: '0 3px 10px rgba(0, 0, 0, .1)'
      })
    }),
    text: {
      address: css({
        ...clamp(1),
        ...font({ color: '#717171', fontSize: '12px', fontWeight: 400, lineHeight: '16px' })
      }),
      label: css({
        ...clamp(1),
        ...font({ color: '#222', fontSize: '16px', fontWeight: 600, lineHeight: '20px' })
      })
    }
  }
  return (
    <Div class={styled.root}>
      <Button alia-aria-label='open' class={styled.button.action} onClick={open}>
        <Div class={styled.icon}>
          <RiSystemSearchLine color='#222' size={20} />
        </Div>
        <Div class={styled.box}>
          <Div class={styled.text.label}>
            現在地以外から探す
          </Div>
          <Switch>
            <Match when={ctx.loading() || data.loading}>
              <Div class={styled.text.address}>
                <Skeleton />
              </Div>
            </Match>
            <Match keyed when={data.latest}>
              {({ address }) => (
                <Div class={styled.text.address}>{address}</Div>
              )}
            </Match>
          </Switch>
        </Div>
      </Button>
    </Div>
  )
}
