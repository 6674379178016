import { Div } from '~/components/html'
import { useRootContext } from '~/contexts'
import { KeenSlider, css, layout, position, shape, size, ux } from '~/libs'

type Props = ComponentProps<'div'>
type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type PresenterProps = Props & {
  ctx: ResolvedContext
  mount: (ref: HTMLDivElement) => Promise<void>
}

export function AddressDrawer(props: Props) {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const mount = async (ref: HTMLDivElement) => {
    await new Promise(resolve => setTimeout(() => resolve(true), 0)) // await 1 tick
    const drawer = new KeenSlider(ref, { drag: false, slides: { perView: 1 }, vertical: true })
    drawer.on('animationEnded', e => {
      if (e.track.details.rel === 0) return ctx.setIsOpenAddress(false)
    })
    ctx.setDrawer2(drawer)
  }
  return <Presenter ctx={ctx} mount={mount} {...props} />
}

function Presenter({ children, ctx, mount }: PresenterProps) {
  const styled = {
    box: css({
      ...size({ height: '100%' })
    }),
    container: css({
      ...size({ height: '100%' }),
      '& > div:first-child': {
        ...layout({ flex: 'none' })
      },
      '& > div:last-child': {
        ...shape({ backgroundColor: '#ebebeb' })
      }
    }),
    root: css({
      ...position({ left: 0, position: 'absolute', top: 0, zIndex: 5 }),
      ...shape({ opacity: 0 }),
      ...size({ height: '100%', width: '100%' }),
      ...ux({ pointerEvents: 'none' })
    }),
    wrapper: css({
      ...position({ left: 0, position: 'absolute', top: 0, ...size({ height: '100%', width: '100%' }) })
    })
  }
  const changed = {
    root: css({
      ...shape({ opacity: 1 }),
      ...ux({ pointerEvents: 'auto' })
    })
  }
  return (
    <Div class={styled.root} classList={{ [changed.root]: ctx.isOpenAddress() }}>
      <Div class={styled.wrapper}>
        <Div class={`${styled.container} keen-slider`} ref={mount}>
          <Div class={`${styled.box} keen-slider__slide`} />
          <Div class={`${styled.box} keen-slider__slide`}>
            {children}
          </Div>
        </Div>
      </Div>
    </Div>
  )
}
