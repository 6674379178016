import { mode as MODE } from '@api/routes/geolocation'
import { For, Match, Show, Switch } from 'solid-js'
import data from '~/assets/Animation - 1697448592147.json'
import { Div } from '~/components/html'
import { RoomListItem } from '~/components/routes/root'
import { VerticalSlider, VerticalSliderSlide, VerticalSliderSpacer } from '~/components/ui'
import { useRootContext, useVirtualContext } from '~/contexts'
import { css, font, layout, position, shape, size, space } from '~/libs'

type PresenterProps = {
  rctx: Exclude<ReturnType<typeof useRootContext>, undefined>
  render: (ref: HTMLDivElement) => void
}

export function RoomList() {
  const vctx = useVirtualContext()
  if (!vctx) throw new Error('vctx is not defined')
  const rctx = useRootContext()
  if (!rctx) throw new Error('rctx is not defined')
  const render = async (ref: HTMLDivElement) => {
    const lottie = await import('lottie-web').then(module => module.default)
    lottie.loadAnimation({
      animationData: data,
      autoplay: true,
      container: ref,
      loop: true,
      renderer: 'svg'
    })
    const slider = vctx.slider()
    if (!slider) return
    vctx.update(slider)
  }
  return <Presenter rctx={rctx} render={render} />
}

function Presenter({ rctx, render }: PresenterProps) {
  const styled = {
    box: css({
      ...shape({ padding: '24px' })
    }),
    lottie: css({
      ...position({ position: 'relative' }),
      ...shape({ paddingTop: `${290 / 426 * 100}%` }), // svg size
      ...size({ width: '100%' }),
      '& > div': {
        ...layout({ display: 'flex' }),
        ...position({ left: 0, position: 'absolute', top: 0 })
      }
    }),
    root: css({
      ...position({ transform: 'translateY(0)', zIndex: 3 }),
      ...shape({ backgroundColor: '#fff' }),
      ...size({ height: '100%' })
    }),
    spacer: {
      first: css({
        ...size({ height: '144px' }),
        ...space({ marginBottom: '24px' })
      }),
      last: {
        0: css({
          ...size({ height: `${1}px` })
        }),
        1: css({
          ...size({ height: `${22 + 1}px` })
        })
      }
    },
    text: {
      notfound: css({
        ...shape({ padding: '24px' }),
        ...font({ color: '#222', fontSize: '37px', fontWeight: 600, lineHeight: '45px' }),
        ...space({ marginBottom: '24px' })
      })
    }
  }
  return (
    <Show when={rctx.mode() === MODE.list && !rctx.isOpenSearch() && !rctx.isOpenAddress() /*&& !ctx.isOpenFilter()*/}>
      <Show keyed when={rctx.store()}>
        {store => (
          <VerticalSlider class={styled.root}>
            <VerticalSliderSpacer class={styled.spacer.first} />
            <Switch>
              <Match when={store.length}>
                <For each={store}>
                  {data => (
                    <RoomListItem data={data} />
                  )}
                </For>
                <VerticalSliderSpacer class={styled.spacer.last[1]} />
              </Match>
              <Match when={!store.length}>
                <VerticalSliderSlide>
                  <Div class={styled.box}>
                    <Div class={styled.lottie}>
                      <Div ref={render} />
                    </Div>
                  </Div>
                </VerticalSliderSlide>
                <VerticalSliderSlide>
                  <Div class={styled.text.notfound}>
                    <Div>
                      おっと、表示可能な教室がないようです。
                    </Div>
                    <Div>検索条件を広げてください。</Div>
                  </Div>
                </VerticalSliderSlide>
                <VerticalSliderSpacer class={styled.spacer.last[0]} />
              </Match>
            </Switch>
          </VerticalSlider>
        )}
      </Show>
    </Show>
  )
}
