import circle from '@turf/circle'
import { createEffect, createMemo, createSignal, createUniqueId, on } from 'solid-js'
import { render } from 'solid-js/web'
import { Button } from '~/components/html'
import { RiMapMapPinUserFill } from '~/components/icons'
import { useRootContext } from '~/contexts'
import { animation, css, emoji, keyframes, mapboxgl, position, shape, size } from '~/libs'

type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>

export function RadiusCircleWithPin() {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const [layer, setLayer] = createSignal<string>()
  const [marker, setMarker] = createSignal<mapboxgl.Marker>()
  const shoudUpdate = createMemo(() => layer() && ctx.center())
  createEffect(on(ctx.map, map => create(ctx, map, setLayer, setMarker), { defer: true }))
  createEffect(on(shoudUpdate, () => update(ctx, { layer, marker }), { defer: true }))
  createEffect(on(ctx.radius, () => {
    if (!layer()) return // not created
    update(ctx, { layer, marker })
  }, { defer: true }))
  return <></>
}

function Marker() {
  const anim = {
    root: keyframes({
      from: { ...animation({ transform: 'translateY(0)' }) },
      to: { ...animation({ transform: 'translateY(10px)' }) }
    })
  }
  const styled = {
    root: css({
      ...animation({ animation: `${anim.root} 2s infinite alternate ease-in-out` }),
      '&::before': {
        ...position({
          content: '""',
          left: '50%',
          position: 'absolute',
          top: '29px',
          transform: 'translate(-50%, -50%)',
          zIndex: -1
        }),
        ...shape({ backgroundColor: '#fff', borderRadius: '100%' }),
        ...size({ height: '40px', width: '40px' })
      }
    })
  }
  return (
    <Button
      aria-label='center'
      class={styled.root}
      onClick={ref => emoji(ref.currentTarget, '🤭')}
    >
      <RiMapMapPinUserFill color='rgb(0, 157, 224)' size={64} />
    </Button>
  )
}

const create = (
  ctx: ResolvedContext,
  map: mapboxgl.Map | undefined,
  setLayer: Setter<string | undefined>,
  setMarker: Setter<mapboxgl.Marker | undefined>
) => {
  const [center, radius] = [ctx.center(), ctx.radius()]
  if (!map || !center || !radius) return
  const data = circle(center.toArray(), radius, { steps: 100, units: 'kilometers' })
  const id = createUniqueId()
  const opts: mapboxgl.AnyLayer = {
    id,
    paint: { 'fill-color': 'rgb(0, 157, 224)', 'fill-opacity': .1 },
    source: { data, type: 'geojson' },
    type: 'fill'
  }
  map.addLayer(opts)
  const element = document.createElement('div')
  render(Marker, element)
  const marker = new mapboxgl.Marker({ element })
  marker.setLngLat(center)
  marker.addTo(map)
  setMarker(marker)
  setLayer(id)
}

const update = (
  ctx: ResolvedContext,
  cached: { layer: Accessor<string | undefined>, marker: Accessor<mapboxgl.Marker | undefined> }
) => {
  const [map, layer, center, radius, marker] = [ctx.map(), cached.layer(), ctx.center(), ctx.radius(), cached.marker()]
  if (!map || !layer || !center || !radius || !marker) return
  const source = map.getSource(layer) as mapboxgl.GeoJSONSource
  const data = circle(center.toArray(), radius, { steps: 100, units: 'kilometers' })
  source.setData(data)
  marker.setLngLat(center)
}
