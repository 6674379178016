import { Button } from '~/components/html'
import { useRootContext } from '~/contexts'
import { css, position, shape, size, ux } from '~/libs'

type Props = ComponentProps<'div'>
type PresenterProps = Props & { open: () => void }

export function ListDrawer(props: Props) {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const open = () => ctx.drawer4()?.moveToIdx(1)
  return <Presenter open={open} {...props} />
}

function Presenter({ children, open }: PresenterProps) {
  const styled = {
    root: css({
      ...position({ bottom: 0, left: 0, position: 'absolute', zIndex: 3 }),
      ...shape({ backgroundColor: '#fff', borderTopLeftRadius: '24px', borderTopRightRadius: '24px' }),
      ...size({ width: '100%' }),
      ...ux({ cursor: 'grab', pointerEvents: 'auto' }),
      '&:active': {
        ...ux({ cursor: 'grabbing' })
      }
    })
  }
  return (
    <Button class={styled.root} onClick={open}>
      {children}
    </Button>
  )
}
