import { For } from 'solid-js'
import { Button, H3 } from '~/components/html'
import { HorizontalSlider, HorizontalSliderSlide, HorizontalSliderSpacer, VerticalSliderSlide } from '~/components/ui'
import { useFilterContext } from '~/contexts'
import { css, font, position, shape, size, space } from '~/libs'

type ResolvedContext = Exclude<ReturnType<typeof useFilterContext>, undefined>
type PresenterProps = { commit: (value: string) => void, ctx: ResolvedContext, defs: { title: string }[] }

export function FilterGrades() {
  const ctx = useFilterContext()
  if (!ctx) throw new Error('ctx is not defined')
  const defs = [
    { title: '未就学児' },
    { title: '小1' },
    { title: '小2' },
    { title: '小3' },
    { title: '小4' },
    { title: '小5' },
    { title: '小6' },
    { title: '中1' },
    { title: '中2' },
    { title: '中3' },
    { title: '高1' },
    { title: '高2' },
    { title: '高3' },
    { title: '既卒' }
  ]
  const commit = (value: string) => ctx.commit(ctx.grades, ctx.setGrades, value)
  return <Presenter commit={commit} ctx={ctx} defs={defs} />
}

function Presenter({ commit, ctx, defs }: PresenterProps) {
  const styled = {
    button: {
      commit: css({
        ...font({ color: '#222', fontSize: '14px', fontWeight: 400, lineHeight: '18px' }),
        ...shape({ backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '30px', padding: '10px 20px' }),
        ...space({ marginRight: '10px' })
      })
    },
    root: css({
      ...shape({ padding: '24px 0' }),
      '&::after': {
        ...position({ bottom: 0, content: '""', left: '24px', position: 'absolute' }),
        ...size({ height: '1px', width: 'calc(100% - 48px)' }),
        ...shape({ backgroundColor: '#ebebeb' })
      }
    }),
    spacer: {
      first: css({
        ...size({ width: '24px' })
      }),
      last: css({
        ...size({ width: `${24 - 10}px` })
      })
    },
    text: {
      title: css({
        ...font({ color: '#222', fontSize: '18px', fontWeight: 600, lineHeight: '22px' }),
        ...shape({ padding: '0 24px' }),
        ...space({ marginBottom: '24px' })
      })
    }
  }
  const selected = {
    button: {
      commit: css({
        ...font({ color: '#fff' }),
        ...shape({ backgroundColor: '#222', borderColor: '#222' })
      })
    }
  }
  return (
    <VerticalSliderSlide class={styled.root}>
      <H3 class={styled.text.title}>受け入れ可能な学年</H3>
      <HorizontalSlider>
        <HorizontalSliderSpacer class={styled.spacer.first} />
        <For each={defs}>
          {({ title }) => (
            <HorizontalSliderSlide>
              <Button
                class={styled.button.commit}
                classList={{ [selected.button.commit]: ctx.has(ctx.grades, title) }}
                onClick={() => commit(title)}
              >
                {title}
              </Button>
            </HorizontalSliderSlide>
          )}
        </For>
        <HorizontalSliderSpacer class={styled.spacer.last} />
      </HorizontalSlider>
    </VerticalSliderSlide>
  )
}
