import { Div } from '~/components/html'
import { animation, css, keyframes, shape } from '~/libs'

export function Skeleton() {
  const anim = {
    root: keyframes({
      from: { ...shape({ opacity: 0.09049773755656108 }) },
      to: { ...shape({ opacity: 0.15384615384615385 }) }
    })
  }
  const styled = {
    root: css({
      ...animation({ animation: `${anim.root} 1s ease-in-out infinite` }),
      ...shape({ backgroundColor: '#222', borderRadius: '4px' })
    })
  }
  return (
    <Div class={styled.root}>loading...</Div>
  )
}
