import { Match, Switch, createEffect, createResource, createSignal, on } from 'solid-js'
import { render } from 'solid-js/web'
import { Button } from '~/components/html'
import { IoSchool } from '~/components/icons'
import { Async } from '~/components/ui'
import { useFilterContext, useRootContext } from '~/contexts'
import { api } from '~/entry-api'
import { css, font, layout, mapboxgl, shape, size } from '~/libs'

type Payload = Parameters<ReturnType<typeof api>['rooms']['v1']['search']['$post']>[0]['json']
type ResolvedData = Awaited<ReturnType<typeof fetcher>>
type PresenterProps = { data: ResolvedData, rctx: Exclude<ReturnType<typeof useRootContext>, undefined> }

const fetcher = (payload: Payload) => api().rooms.v1.search.$post({ json: payload }).then(res => res.json())

export function MapboxPins() {
  const rctx = useRootContext()
  if (!rctx) throw new Error('rctx is not defined')
  const fctx = useFilterContext()
  if (!fctx) throw new Error('fctx is not defined')
  const [payload, setPayload] = createSignal<Payload>()
  createEffect(on([rctx.center, rctx.radius, fctx.store], ([center, radius, filter]) => {
    if (!center || !radius) return
    rctx.setPin(0)
    const { lat, lng } = center
    setPayload({ filter, lat, lng, radius })
  }, { defer: true }))
  const [data] = createResource(payload, fetcher)
  return <Async component={data => <Resolver data={data} rctx={rctx} />} data={data} />
}

function Resolver({ data, rctx }: PresenterProps) {
  rctx.setStore(data)
  const elements = document.querySelectorAll('[data-label="pin"]')
  elements.forEach(element => element.remove())
  createEffect(() => data.forEach(item => create(rctx, item)))
  return <></>
}

function Marker(rctx: PresenterProps['rctx'], id: number) {
  const styled = {
    root: css({
      ...font({ color: '#222', fontSize: '14px', fontWeight: 700, lineHeight: '18px' }),
      ...layout({ display: 'grid', placeContent: 'center' }),
      ...shape({
        backgroundColor: '#fff',
        borderRadius: '28px',
        boxShadow: 'rgb(0 0 0 / 4%) 0 0 0 1px, rgb(0 0 0 / 18%) 0 2px 4px',
        padding: '0 8px'
      }),
      ...size({ height: '28px' })
    })
  }
  const selected = {
    root: css({ ...shape({ backgroundColor: '#222' }) })
  }
  return (
    <Switch>
      <Match when={rctx.pin() !== id}>
        <Button
          aria-label='pin'
          class={styled.root}
          classList={{ [selected.root]: rctx.pin() === id }}
          onClick={() => rctx.setPin(id)}
        >
          <IoSchool color={rctx.pin() === id ? '#fff' : '#222'} size={16} />
        </Button>
      </Match>
      <Match when={rctx.pin() === id}>
        <Button
          aria-label='pin'
          class={`${styled.root} ${selected.root}`}
          classList={{ [selected.root]: rctx.pin() === id }}
          onClick={() => rctx.setPin(id)}
        >
          <IoSchool color={rctx.pin() === id ? '#fff' : '#222'} size={16} />
        </Button>
      </Match>
    </Switch>
  )
}

const create = (rctx: PresenterProps['rctx'], data: ResolvedData[0]) => {
  const [map, center] = [rctx.map(), rctx.center()]
  if (!map || !center) return
  const element = document.createElement('div')
  element.dataset.label = 'pin'
  render(() => Marker(rctx, data.id), element)
  const marker = new mapboxgl.Marker({ element })
  marker.setLngLat({ lat: data.latitude, lng: data.longitude })
  marker.addTo(map)
}
