import { Div } from '~/components/html'
import { css, layout } from '~/libs'

type Props = ComponentProps<'div'>
type PresenterProps = Props & { mount: (ref: HTMLDivElement) => void }

export function HorizontalSlider(props: Props) {
  const mount = (ref: HTMLDivElement) => {
    import('@splidejs/splide').then(module => {
      const Splide = module.default
      new Splide(ref, { arrows: false, autoWidth: true }).mount()
    })
  }
  return <Presenter mount={mount} {...props} />
}

function Presenter({ children, mount }: PresenterProps) {
  const styled = {
    root: css({
      '& > .splide__pagination': {
        ...layout({ display: 'none' })
      }
    })
  }
  return (
    <Div class={`splide ${styled.root}`} ref={mount}>
      <Div class='splide__track'>
        <Div class='splide__list'>
          {children}
        </Div>
      </Div>
    </Div>
  )
}
