import { For, Show } from 'solid-js'
import { Button, Dd, Div, Dl, Dt, H3 } from '~/components/html'
import { BsCheck } from '~/components/icons'
import { VerticalSliderSlide } from '~/components/ui'
import { useFilterContext } from '~/contexts'
import { css, font, layout, position, shape, size, space } from '~/libs'

type ResolvedContext = Exclude<ReturnType<typeof useFilterContext>, undefined>
type PresenterProps = {
  commit: (value: string) => void
  ctx: ResolvedContext
  defs: { description: string, title: string }[]
}

export function FilterCourses() {
  const ctx = useFilterContext()
  if (!ctx) throw new Error('ctx is not defined')
  const defs = [
    { description: '語学力の基礎を身につける、異文化への理解を深めることが出来る', title: '子供英語' },
    { description: '受験対策は小学3年冬、小学4年頃から始めるのが一般的', title: '中学受験' },
    { description: '早期入塾で内申点対策が可能、難関高校や推薦入試が目指せるようになる', title: '高校受験' },
    {
      description: '専用の対策が欠かせないが、6年を通じたきめ細やかな教育が費用を抑え受けられる',
      title: '公立中高一貫校'
    },
    { description: '大学受験を意識するなら高2からの通塾が適切、高1からの通い始めも少なくない', title: '大学受験' },
    { description: '医学部受験に特化しているため、必要な学習や対策がより効率よく行える', title: '医学部受験' },
    {
      description: '自ら主体的に学習する力を育むことで、学習の習慣が身に付いたり学習以外にも役立てられる',
      title: '自立型学習'
    },
    {
      description: 'Scratch や Minecraft を用いた学習カリキュラムが充実、やり抜く力（GRIT）を育む',
      title: 'プログラミング・ロボット'
    },
    { description: 'ローコスト・ハイクオリティで、自分のペースでその日の授業を進められる', title: '映像授業' },
    {
      description: '情報の考察力とそこからの行動力が身につく、実験を通して将来役に立つ力を身につける',
      title: '理科実験'
    }
  ]
  const commit = (value: string) => ctx.commit(ctx.courses, ctx.setCourses, value)
  return <Presenter commit={commit} ctx={ctx} defs={defs} />
}

function Presenter({ commit, ctx, defs }: PresenterProps) {
  const styled = {
    box: css({
      ...shape({ padding: '12px 24px' })
    }),
    button: {
      action: css({
        ...layout({ alignItems: 'center', display: 'grid', gridTemplateColumns: 'auto 1fr' })
      })
    },
    container: css({
      '& > div:first-child ': {
        ...shape({ padding: '0 24px 12px' })
      },
      '& > div:last-child ': {
        ...shape({ padding: '12px 24px 24px' }),
        '&::after': {
          ...position({ bottom: 0, content: '""', left: '24px', position: 'absolute' }),
          ...size({ height: '1px', width: 'calc(100% - 48px)' }),
          ...shape({ backgroundColor: '#ebebeb' })
        }
      }
    }),
    icon: css({
      ...layout({ display: 'grid', placeContent: 'center' }),
      ...shape({ backgroundColor: '#fff', border: '1px solid #b0b0b0', borderRadius: '4px' }),
      ...size({ height: '24px', width: '24px' }),
      ...space({ marginLeft: '16px' })
    }),
    root: css({
      ...shape({ padding: '24px' })
    }),
    text: {
      button: {
        description: css({
          ...font({ color: '#717171', fontSize: '14px', fontWeight: 400, lineHeight: '18px' }),
          ...space({ marginTop: '4px' })
        }),
        title: css({
          ...font({ color: '#222', fontSize: '16px', fontWeight: 400, lineHeight: '20px' })
        })
      },
      title: css({
        ...font({ color: '#222', fontSize: '18px', fontWeight: 600, lineHeight: '22px' })
      })
    }
  }
  const selected = {
    icon: css({
      ...shape({ backgroundColor: '#222 !important' })
    })
  }
  return (
    <>
      <VerticalSliderSlide class={styled.root}>
        <H3 class={styled.text.title}>特別コースから探す</H3>
      </VerticalSliderSlide>
      <Div class={styled.container}>
        <For each={defs}>
          {({ description, title }) => (
            <VerticalSliderSlide class={styled.box}>
              <Button class={styled.button.action} onClick={() => commit(title)}>
                <Dl>
                  <Dt class={styled.text.button.title}>{title}</Dt>
                  <Dd class={styled.text.button.description}>{description}</Dd>
                </Dl>
                <Div class={styled.icon} classList={{ [selected.icon]: ctx.has(ctx.courses, title) }}>
                  <Show when={ctx.has(ctx.courses, title)}>
                    <BsCheck color='#fff' size={16} />
                  </Show>
                </Div>
              </Button>
            </VerticalSliderSlide>
          )}
        </For>
      </Div>
    </>
  )
}
