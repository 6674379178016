import { createGeolocation } from '@solid-primitives/geolocation'
import { Match, Switch, createSignal } from 'solid-js'
import { Button } from '~/components/html'
import { RiMapNavigationFill } from '~/components/icons'
import { Async } from '~/components/ui'
import { useRootContext } from '~/contexts'
import { css, layout, position, shape, size } from '~/libs'

type ResolvedData = GeolocationCoordinates | undefined
type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type MediatorProps = { ctx: ResolvedContext }
type CoreProps = MediatorProps & { data: ResolvedData, refetch: VoidFunction }
type PresenterProps = { action: () => void }

export function YoureHere() {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const [isClicked, setIsClicked] = createSignal(false)
  return (
    <Switch>
      <Match when={isClicked()}>
        <Mediator ctx={ctx} />
      </Match>
      <Match when={!isClicked()}>
        <Presenter action={() => setIsClicked(true)} />
      </Match>
    </Switch>
  )
}

function Mediator({ ctx }: MediatorProps) {
  const [data, refetch] = createGeolocation()
  return (
    <Switch>
      <Match when={['pending', 'refreshing', 'ready'].includes(data.state)}>
        <Async component={data => <Core ctx={ctx} data={data} refetch={refetch} />} data={data} />
      </Match>
      <Match when={['errored'].includes(data.state)}>
        <Presenter action={refetch} />
      </Match>
    </Switch>
  )
}

function Core({ ctx, data, refetch }: CoreProps) {
  if (data?.latitude && data?.longitude) {
    ctx.map()?.flyTo({ center: { lat: data.latitude, lon: data.longitude } })
  }
  return <Presenter action={refetch} />
}

function Presenter({ action }: PresenterProps) {
  const styled = {
    button: css({
      ...layout({ display: 'grid', placeContent: 'center' }),
      ...position({ position: 'absolute', right: '16px', top: '160px', transform: 'rotate(90deg)', zIndex: 2 }),
      ...shape({ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 6px 16px rgba(0, 0, 0, .12)' }),
      ...size({ height: '40px', width: '40px' })
    })
  }
  return (
    <Button aria-label='location' class={styled.button} onClick={action}>
      <RiMapNavigationFill color='#b0b0b0' size={20} />
    </Button>
  )
}
