import { createSignal } from 'solid-js'
import { Div } from '~/components/html'
import { useRootContext } from '~/contexts'
import { KeenSlider, animation, css, keyframes, layout, position, shape, size, ux } from '~/libs'

type Props = ComponentProps<'div'>
type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type PresenterProps = Props & {
  close: () => void
  ctx: ResolvedContext
  mount: (ref: HTMLDivElement) => Promise<void>
  rel: Accessor<number | undefined>
}

export function FilterDrawer(props: Props) {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const [rel, setRel] = createSignal<number>()
  const mount = async (ref: HTMLDivElement) => {
    await new Promise(resolve => setTimeout(() => resolve(true), 0)) // await 1 tick
    const drawer = new KeenSlider(ref, { drag: false, slides: { perView: 1 }, vertical: true })
    drawer.on('animationEnded', e => {
      if (e.track.details.rel === 0) return ctx.setIsOpenFilter(false)
    })
    drawer.on('animationStarted', e => setRel(e.track.details.rel))
    ctx.setDrawer3(drawer)
  }
  const close = () => ctx.drawer3()?.moveToIdx(0)
  return <Presenter close={close} ctx={ctx} mount={mount} rel={rel} {...props} />
}

function Presenter({ children, close, ctx, mount, rel }: PresenterProps) {
  const anim = {
    layer: {
      end: keyframes({ from: { ...shape({ opacity: .6 }) }, to: { ...shape({ opacity: 0 }) } }),
      start: keyframes({ from: { ...shape({ opacity: 0 }) }, to: { ...shape({ opacity: .6 }) } })
    }
  }
  const styled = {
    box: css({
      ...size({ height: '100%' })
    }),
    container: css({
      ...size({ height: '100%' }),
      '& > div:first-child': {
        ...layout({ flex: 'none' })
      }
    }),
    core: css({
      ...layout({ flex: 1 }),
      ...shape({ backgroundColor: '#fff', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }),
      ...size({ height: '100%' })
    }),
    item: css({
      ...layout({ display: 'flex', flexFlow: 'column' }),
      ...size({ height: '100%' })
    }),
    layer: css({
      ...position({ left: 0, position: 'absolute', top: 0 }),
      ...shape({ backgroundColor: '#222' }),
      ...size({ height: '100%', width: '100%' })
    }),
    root: css({
      ...position({ left: 0, position: 'absolute', top: 0, zIndex: 5 }),
      ...shape({ opacity: 0 }),
      ...size({ height: '100%', width: '100%' }),
      ...ux({ pointerEvents: 'none' })
    }),
    spacer: css({
      ...size({ minHeight: '12px' }),
      ...ux({ cursor: 'pointer' })
    }),
    wrapper: css({
      ...position({ left: 0, position: 'absolute', top: 0, ...size({ height: '100%', width: '100%' }) })
    })
  }
  const changed = {
    layer: {
      end: css({ ...animation({ animation: `${anim.layer.end} .4s 1 both ease-in-out` }) }),
      start: css({ ...animation({ animation: `${anim.layer.start} .4s 1 both ease-in-out` }) })
    },
    root: css({
      ...shape({ opacity: 1 }),
      ...ux({ pointerEvents: 'auto' })
    })
  }
  return (
    <Div class={styled.root} classList={{ [changed.root]: ctx.isOpenFilter() }}>
      <Div
        class={styled.layer}
        classList={{ [changed.layer.start]: rel() === 0, [changed.layer.end]: rel() === 1 }}
      />
      <Div class={styled.wrapper}>
        <Div class={`${styled.container} keen-slider`} ref={mount}>
          <Div class={`${styled.box} keen-slider__slide`} />
          <Div class={`${styled.box} keen-slider__slide`}>
            <Div class={styled.item}>
              <Div class={styled.spacer} onClick={close} />
              <Div class={styled.core}>
                {children}
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}
