import type { ReturnPlacesResult } from '@api/routes/geocoding'
import type { ReturnSearchResult } from '@api/routes/rooms'
import { createContextProvider } from '@solid-primitives/context'
import { createEffect, createSignal, on } from 'solid-js'
import { isServer } from 'solid-js/web'
import { useNavigate, useServerContext } from 'solid-start'
import Cookies from 'universal-cookie'

const { VITE_APP_DOMAIN } = import.meta.env

const [RootContextProvider, useRootContext] = createContextProvider(() => {
  const event = useServerContext()
  const navigate = useNavigate()

  const reader = new Cookies(isServer ? event.request.headers.get('cookie') : document)
  const expires = new Date()
  expires.setDate(expires.getDate() + 14)
  const writer = new Cookies(undefined, {
    domain: VITE_APP_DOMAIN,
    expires,
    path: '/',
    sameSite: 'strict',
    secure: true
  })

  const [center, setCenter] = createSignal<mapboxgl.LngLat>() // will consider in next update 2023/10/17

  const [radius, setRadius] = createSignal(reader.get<number | undefined>('radius'))
  const [zoom, setZoom] = createSignal(reader.get<number | undefined>('zoom'))
  const [mode, setMode] = createSignal<number>() // will consider in next update 2023/10/17
  const [pin, setPin] = createSignal(reader.get<number | undefined>('pin'))

  const [loading, setLoading] = createSignal(true)
  const [map, setMap] = createSignal<mapboxgl.Map>()

  const [store, setStore] = createSignal<ReturnSearchResult[]>()

  const [drawer1, setDrawer1] = createSignal<KeenSliderInstance>() // search
  const [drawer2, setDrawer2] = createSignal<KeenSliderInstance>() // address
  const [drawer3, setDrawer3] = createSignal<KeenSliderInstance>() // filter
  const [drawer4, setDrawer4] = createSignal<KeenSliderInstance>() // btttom
  const [drawer5, setDrawer5] = createSignal<KeenSliderInstance>() // favorite

  const [isOpenSearch, setIsOpenSearch] = createSignal(false)
  const [isOpenAddress, setIsOpenAddress] = createSignal(false)
  const [isOpenFilter, setIsOpenFilter] = createSignal(false)
  const [isOpenFavorite, setIsOpenFavorite] = createSignal(false)

  const [features, setFeatures] = createSignal<ReturnPlacesResult['features']>()

  createEffect(on(center, center => {
    if (!center) return
    const { lat, lng } = center
    writer.set('lat', lat)
    writer.set('lng', lng)
  }, { defer: true }))

  createEffect(on(radius, radius => {
    if (!radius) return
    writer.set('radius', radius)
  }, { defer: true }))

  createEffect(on(zoom, zoom => {
    if (!zoom) return
    writer.set('zoom', zoom)
  }, { defer: true }))

  createEffect(on(mode, mode => {
    if (typeof mode === 'undefined') return
    writer.set('mode', mode)
  }, { defer: true }))

  createEffect(on(pin, pin => {
    if (typeof pin === 'undefined') return
    writer.set('pin', pin)
  }, { defer: true }))

  createEffect(on([center, radius, zoom, mode, pin], ([c, r, z, m, p]) => {
    if (!c) return
    const { lat, lng } = c
    const values = [lat, lng, r, z, m, p]
    if (values.some(value => value === undefined)) return
    navigate(`/?lat=${lat}&lng=${lng}&radius=${r}&zoom=${z}&mode=${m}&pin=${p}`)
  }, { defer: true }))

  createEffect(on(loading, loading => {
    if (!loading) return
    setPin(0)
    setStore(undefined)
  }, { defer: true }))

  createEffect(on(store, store => {
    if (!store) return
    setLoading(false)
  }, { defer: true }))

  return {
    center,
    drawer1,
    drawer2,
    drawer3,
    drawer4,
    drawer5,
    features,
    isOpenAddress,
    isOpenFavorite,
    isOpenFilter,
    isOpenSearch,
    loading,
    map,
    mode,
    pin,
    radius,
    setCenter,
    setDrawer1,
    setDrawer2,
    setDrawer3,
    setDrawer4,
    setDrawer5,
    setFeatures,
    setIsOpenAddress,
    setIsOpenFavorite,
    setIsOpenFilter,
    setIsOpenSearch,
    setLoading,
    setMap,
    setMode,
    setPin,
    setRadius,
    setStore,
    setZoom,
    store,
    zoom
  }
})

export { RootContextProvider, useRootContext }
