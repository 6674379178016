import { Show } from 'solid-js'
import { Button, Div, Span } from '~/components/html'
import { RiSystemSearchLine } from '~/components/icons'
import { VerticalSlider, VerticalSliderSlide } from '~/components/ui'
import { useRootContext } from '~/contexts'
import { css, font, layout, shape, size, space } from '~/libs'

type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type PresenterProps = { ctx: ResolvedContext, open: () => void }

export function SearchAddress() {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  const open = () => {
    ctx.setIsOpenAddress(true)
    ctx.drawer2()?.moveToIdx(1, true, { duration: 0 }) // no animation
    ctx.drawer2()?.on('detailsChanged', e => {
      if (e.track.details.rel !== 1) return
      void (document.querySelector('[name=landmark]') as HTMLInputElement | null)?.focus() // open with focus
    })
  }

  return <Presenter ctx={ctx} open={open} />
}

function Presenter({ ctx, open }: PresenterProps) {
  const styled = {
    button: {
      open: css({
        ...layout({ display: 'flex', flexFlow: 'column' }),
        ...shape({
          backgroundColor: '#fff',
          borderRadius: '16px',
          boxShadow: '0 0 0 1px rgb(0 0 0 / 4%), 0 6px 20px rgb(0 0 0 / 20%)',
          padding: '24px'
        }),
        ...size({ height: '324px', width: '100%' }),
        ...space({ marginBottom: '24px' })
      })
    },
    container: css({
      ...shape({ padding: '15px 12px 0' })
    }),
    icon: css({
      ...layout({ display: 'flex' })
    }),
    input: css({
      ...font({ color: '#717171', fontSize: '14px', fontWeight: 600, lineHeight: '18px' }),
      ...layout({ alignItems: 'center', display: 'flex' }),
      ...shape({ backgroundColor: '#fff', border: '1px solid #b0b0b0', borderRadius: '12px', padding: '0 20px' }),
      ...size({ height: '60px' }),
      ...space({ columnGap: '12px' })
    }),
    text: {
      title: css({
        ...font({ color: '#222', fontSize: '22px', fontWeight: 600, lineHeight: '26px' }),
        ...space({ marginBottom: '16px' })
      })
    }
  }
  return (
    <Show when={ctx.isOpenSearch()}>
      <VerticalSlider space={60}>
        <VerticalSliderSlide class={styled.container}>
          <Button aria-label='open' class={styled.button.open} onClick={open}>
            <Div class={styled.text.title}>現在地以外から</Div>
            <Div class={styled.input}>
              <Span class={styled.icon}>
                <RiSystemSearchLine size={16} />
              </Span>
              住所やランドマークから検索
            </Div>
          </Button>
        </VerticalSliderSlide>
      </VerticalSlider>
    </Show>
  )
}
