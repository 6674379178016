import { createContextProvider } from '@solid-primitives/context'
import { createEffect, createMemo, createSignal, on } from 'solid-js'
import { batch } from 'solid-js'
import { isServer } from 'solid-js/web'
import { useServerContext } from 'solid-start'
import Cookies from 'universal-cookie'
import { useRootContext } from '~/contexts'

type Filter = {
  courses?: string[]
  features?: string[]
  grades?: string[]
  radius?: number
  types?: string[]
}

const { VITE_APP_DOMAIN } = import.meta.env

const [FilterContextProvider, useFilterContext] = createContextProvider(() => {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')

  const event = useServerContext()
  const reader = new Cookies(isServer ? event.request.headers.get('cookie') : document)
  const expires = new Date()
  expires.setDate(expires.getDate() + 14)
  const writer = new Cookies(undefined, {
    domain: VITE_APP_DOMAIN,
    expires,
    path: '/',
    sameSite: 'strict',
    secure: true
  })

  const filter = reader.get<Filter>('filter')

  const [types, setTypes] = createSignal(new Set<string>(filter?.types ?? [])) // multi
  const [radius, setRadius] = [ctx.radius, ctx.setRadius] // single
  const [grades, setGrades] = createSignal(new Set<string>(filter?.grades ?? [])) // multi
  const [courses, setCourses] = createSignal(new Set<string>(filter?.courses ?? [])) // multi
  const [features, setFeatures] = createSignal(new Set<string>(filter?.features ?? [])) // multi

  const store = createMemo(() => {
    return {
      courses: [...courses()],
      features: [...features()],
      grades: [...grades()],
      types: [...types()]
    }
  })

  createEffect(on(store, store => {
    writer.set('filter', store)
  }, { defer: true }))

  const has = (accessor: Accessor<Set<string | number>>, value: string | number) => {
    return accessor().has(value)
  }

  const add = (
    accessor: Accessor<Set<string | number>>,
    setter: Setter<Set<string | number>>,
    value: string | number
  ) => {
    accessor().add(value)
    setter(new Set(accessor()))
  }

  const remove = (
    accessor: Accessor<Set<string | number>>,
    setter: Setter<Set<string | number>>,
    value: string | number
  ) => {
    accessor().delete(value)
    setter(new Set(accessor()))
  }

  const commit = (
    accessor: Accessor<Set<string | number>>,
    setter: Setter<Set<string | number>>,
    value: string | number
  ) => {
    if (has(accessor, value)) {
      remove(accessor, setter, value)
    } else {
      add(accessor, setter, value)
    }
  }

  const clear = () => {
    batch(() => {
      const empty = new Set<string>([])
      if (types().size) setTypes(empty)
      if (grades().size) setGrades(empty)
      if (courses().size) setCourses(empty)
      if (features().size) setFeatures(empty)
    })
  }

  const hasCond = createMemo(() => {
    const encode = encodeURIComponent(JSON.stringify(store()))
    if (
      encode === // default
        '%7B%22courses%22%3A%5B%5D%2C%22features%22%3A%5B%5D%2C%22grades%22%3A%5B%5D%2C%22types%22%3A%5B%5D%7D'
    ) return false
    return true
  })

  return {
    add,
    clear,
    commit,
    courses,
    features,
    grades,
    has,
    hasCond,
    radius,
    remove,
    setCourses,
    setFeatures,
    setGrades,
    setRadius,
    setTypes,
    store,
    types
  }
})

export { FilterContextProvider, useFilterContext }
