import { zValidator } from '@hono/zod-validator'
import { z } from 'zod'

export enum mode {
  infer,
  map,
  list,
  favorite
}

export function Route(app: Hono) {
  const defs = { lat: 35.6574164, lng: 139.7048337, mode: mode.map, pin: 0, radius: 0.2, zoom: 16 }
  const payload = {
    coordinate: z.object({
      lat: z.number({ invalid_type_error: 'lat must be number' }).optional(),
      lng: z.number({ invalid_type_error: 'lng must be number' }).optional(),
      mode: z.number({ invalid_type_error: 'mode must be number' }).optional(),
      pin: z.number({ invalid_type_error: 'pin must be number' }).optional(),
      radius: z.number({ invalid_type_error: 'radius must be number' }).optional(),
      zoom: z.number({ invalid_type_error: 'zoom must be number' }).optional()
    })
  }

  return app
    .post('/geolocation/v1/coordinate', zValidator('json', payload.coordinate), c => {
      const payload = c.req.valid('json')
      const [lat, lng, radius, zoom, mode, pin] = [
        payload.lat ?? defs.lat,
        payload.lng ?? defs.lng,
        payload.radius ?? defs.radius,
        payload.zoom ?? defs.zoom,
        payload.mode ?? defs.mode,
        payload.pin ?? defs.pin
      ]
      const result = { lat, lng, mode, pin, radius, zoom }
      // console.log({ result })
      return c.jsonT(result)
    })
}
