import { Show } from 'solid-js'
import { Animate, Circle, Div, Svg } from '~/components/html'
import { useRootContext } from '~/contexts'
import { css, layout, position, shape, size } from '~/libs'

type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type PresenterProps = { ctx: ResolvedContext }

export function Loading() {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  return <Presenter ctx={ctx} />
}

function Presenter({ ctx }: PresenterProps) {
  const styled = {
    root: css({
      ...layout({ display: 'grid', placeContent: 'center' }),
      ...position({ left: '50%', position: 'absolute', top: '160px', transform: 'translateX(-50%)', zIndex: 4 }),
      ...size({ height: '40px', width: '72px' }),
      ...shape({ backgroundColor: '#fff', borderRadius: '48px', boxShadow: '0 6px 16px rgba(0, 0, 0, .12)' })
    })
  }
  return (
    <Show when={ctx.loading()}>
      <Div class={styled.root}>
        <Svg fill='#222' height='7.5' viewBox='0 0 120 30' width='30' xmlns='http://www.w3.org/2000/svg'>
          <Circle cx='15' cy='15' r='15'>
            <Animate
              attributeName='r'
              begin='0s'
              calcMode='linear'
              dur='0.8s'
              from='15'
              repeatCount='indefinite'
              to='15'
              values='15;9;15'
            />
            <Animate
              attributeName='fill-opacity'
              begin='0s'
              calcMode='linear'
              dur='0.8s'
              from='1'
              repeatCount='indefinite'
              to='1'
              values='1;.5;1'
            />
          </Circle>
          <Circle cx='60' cy='15' fill-opacity='0.3' r='9'>
            <Animate
              attributeName='r'
              begin='0s'
              calcMode='linear'
              dur='0.8s'
              from='9'
              repeatCount='indefinite'
              to='9'
              values='9;15;9'
            />
            <Animate
              attributeName='fill-opacity'
              begin='0s'
              calcMode='linear'
              dur='0.8s'
              from='0.5'
              repeatCount='indefinite'
              to='0.5'
              values='.5;1;.5'
            />
          </Circle>
          <Circle cx='105' cy='15' r='15'>
            <Animate
              attributeName='r'
              begin='0s'
              calcMode='linear'
              dur='0.8s'
              from='15'
              repeatCount='indefinite'
              to='15'
              values='15;9;15'
            />
            <Animate
              attributeName='fill-opacity'
              begin='0s'
              calcMode='linear'
              dur='0.8s'
              from='1'
              repeatCount='indefinite'
              to='1'
              values='1;.5;1'
            />
          </Circle>
        </Svg>
      </Div>
    </Show>
  )
}
