import { Show } from 'solid-js'
import { FilterCourses, FilterFeatures, FilterGrades, FilterRadius, FilterTypes } from '~/components/routes/root'
import { VerticalSlider, VerticalSliderSpacer } from '~/components/ui'
import { useRootContext } from '~/contexts'
import { css, size } from '~/libs'

type ResolvedContext = Exclude<ReturnType<typeof useRootContext>, undefined>
type PresenterProps = { ctx: ResolvedContext }

export function FilterList() {
  const ctx = useRootContext()
  if (!ctx) throw new Error('ctx is not defined')
  return <Presenter ctx={ctx} />
}

function Presenter({ ctx }: PresenterProps) {
  const styled = {
    spacer: css({
      ...size({ height: '81px' })
    })
  }
  return (
    <Show when={ctx.isOpenFilter()}>
      <VerticalSlider space={12 + 48}>
        <FilterTypes />
        <FilterRadius />
        <FilterGrades />
        <FilterCourses />
        <FilterFeatures />
        <VerticalSliderSpacer class={styled.spacer} />
      </VerticalSlider>
    </Show>
  )
}
