import cover from '~/assets/resize/732x696.png'
import { Div, Img } from '~/components/html'
import { Requested } from '~/components/ui'
import { css, layout, position, scale, shape, space } from '~/libs'

type Props = { id: number }

export function RoomListItemCover({ id }: Props) {
  const styled = {
    root: css({
      ...layout({ display: 'flex' }),
      ...position({ position: 'relative' }),
      ...shape({ backgroundColor: '#f7f7f7', borderRadius: '8px' }),
      ...space({ marginBottom: '12px' }),
      '& > img': {
        ...scale({ objectFit: 'cover' }),
        ...shape({ borderRadius: '8px' })
      }
    })
  }
  return (
    <Div class={styled.root}>
      <Img height={348} src={cover} width={366} />
      <Requested id={id} position={{ left: '16px', position: 'absolute', top: '17px' }} />
    </Div>
  )
}
